import { BASE_URL } from "../../global";

/***
 * name: get_groups
 * objective: API to get the list of groups for the current user.
 * args: recordPerPage => how many record per page , pageNo => the current page number
 * return promise response
 */
const get_groups = async (recordPerPage, pageNo) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const res = (
    await fetch(`${BASE_URL}/v4/settings/groups/${recordPerPage}/${pageNo}`, {
      method: "GET",
      headers: headers,
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name: get_library_documents
 * objective: API to retieve documents from both personal and enterprise libraries for the current user.
 * args: recordPerPage => how many record per page , pageNo => the current page number
 * return promise response
 */
const get_library_documents = async (
  recordPerPage,
  pageNo,
  x_search_text,
  x_folder
) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
    "x-folder": x_folder,
  };
  if (x_search_text) {
    headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-search-text": x_search_text,
      "x-folder": x_folder,
    };
  }
  const res = (
    await fetch(`${BASE_URL}/v4/settings/library/${recordPerPage}/${pageNo}`, {
      method: "GET",
      headers: headers,
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name: get_signature_delegation_settings
 * objective: this service API to get delegation settings of logged in user.
 * args: none
 * return promise response
 */
const get_signature_delegation_settings = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/delegate`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name:  update_signature_delegation_settings
 * objective: use this service API to update the signature delegation settings of the current user
 * params: enabled true / false ,  delegates = [
 *  {
 *   user_email: Email address of the user to whom the signing action will be delegated to.
 *   user_name: Name of the user to whom signing responsibilities are delegated to.
 *   from: Date and time from when the delegation is enabled from.
 *   to: Date and time to when the delegation is enabled to
 *   }]
 * "gatekeeper_setting": {
"enabled": true,
"gatekeeper": {
"user_name": "string",
"user_email": "string"
}
}
 * return promise response
 *
 */
const update_signature_delegation_settings = async (
  enabled,
  delegates,
  gatekeeper_setting
) => {
  const postData = {
    enabled: enabled,
    delegate: delegates,
    gatekeeper_setting: gatekeeper_setting,
  };

  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/delegate`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

/***
 * name: change_password
 * objective: use this service API to change the password of the current user
 * args: old_pass => user current password , new_pass => the new password
 * return promise response
 */
const change_password = async (old_pass, new_pass) => {
  const postData = {
    user_old_password: old_pass,
    user_new_password: new_pass,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/profile/password`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

/***
 * name: get_hand_signature_text
 * objective: use this service API to get hand signature text
 * args: none
 * return promise response
 */
const get_hand_signature_text = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(
    `${BASE_URL}/v4/settings/signatures/appearance/hand_signature/web/text`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const blob = await res.blob();
  const img = URL.createObjectURL(blob);
  return img;
};

/***
 * name: get_initials_for_upload_option
 * objective: use this service API to upload intials
 * args: none
 * return promise response
 */
const get_initials_for_upload_option = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(
      `${BASE_URL}/v4/settings/signatures/appearance/initials/upload`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();

  const body = await res;
  return body;
};

/***
 * name: get_profile_picture
 * objective: use this service API to get profile picture of the current user
 * args: none
 * return promise response
 */
const get_general_profile_picture = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/profile/general/photo/base64`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name: update_profile_picture
 * objective: use this service API to update profile picture of the current user
 * args: img => Base64 encoded string of image to be updated as profile picture.
 * return promise response
 */
const update_profile_picture = async (img) => {
  const postData = {
    photo: img,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(
    `${BASE_URL}/v4/settings/profile/general/photo/base64`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    }
  );

  return res;
};

/***
 * name: get_hand_signature_upload_for_mobile
 * objective: use this service API to upload hand signature for mobile
 * args: none
 * return promise response
 */
const get_hand_signature_upload_for_mobile = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(
      `${BASE_URL}/v4/settings/signatures/appearance/hand_signature/mobile/upload`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();

  const body = await res;
  return body;
};

/***
 * name: get_hand_signature_upload_for_web
 * objective: use this service API to upload hand signature for web
 * args: none
 * return promise response
 */
const get_hand_signature_upload_for_web = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(
      `${BASE_URL}/v4/settings/signatures/appearance/hand_signature/web/upload`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();

  const body = await res;
  return body;
};

/***
 * name: get_signature_settings
 * objective:  use this service API to get the signature settings for the current user
 * args: none
 * return promise response
 */
const get_signature_settings = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/signatures`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

const update_signature_settings = async (values) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    level_of_assurance: {
      signature: values?.assurance_signature,
      in_person: values?.in_person_signature,
    },
    appearance: {
      signature: {
        web: {
          default_method: values?.hand_signature,
          upload_image:
            values?.hand_signature === "UPLOAD"
              ? values?.upload_image_web
              : values?.text_image,
          text_value: values?.text_value_web,
        },
        mobile: {
          default_method: values?.hand_msignature,
          upload_image: values?.upload_image,
          text_value: values?.hand_mtext_value,
        },
      },
      initials: {
        default_method: values?.initials,
        upload_image: values?.upload_initial_image,
        text_value: values?.itext_value,
      },
      appearance_design: {
        name: values?.signature_appearance_design,
        // logo: values?.logo, // here get entreprise logo
      },
    },
  };

  const res = await fetch(`${BASE_URL}/v4/settings/signatures`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
    body: JSON.stringify(postData),
  });
  return res;
};

/***
 * name: get_initials_for_text_option
 * objective:  get the initials appearance used for text option.
 * args: none
 * return promise response
 */
const get_initials_for_text_option = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(
    `${BASE_URL}/v4/settings/signatures/appearance/initials/text`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  const blob = await res.blob();
  const img = URL.createObjectURL(blob);
  return img;
};

/***
 * name: get_hand_signature_text_for_mobile
 * objective:  get the hand signature appearance used for text option on mobile apps and browsers.
 * args: none
 * return promise response
 */
const get_hand_signature_text_for_mobile = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  // const token =
  //   "Plj2zOHnvKTf-B5KRkvhtNASJECsJWoJuL-MePP6mWrmWtxzd4q7bb_cyE2AGzH3TFSYbRnUvAIFAFhP2r8vKKiviSluOlPOMZDPnpZPPbEOaH0Ku3HbXrY36FpakUHRkVV3wS2BDrM3YUaDSD8ngKyamG9sR3i5u1fYgXHFuxvC1hW-bzfro_7rHEx8PFI7iTduRhxxFKvBz27Vhwffo3b5nivm2upOVSw8QtOJJTusfwHjiyygPRRbXraC1ay8ueDggEO2UxLK6o0RZa_I7pfosPacAFsVFdtkHHJVCWJE3-Zrs_8HrF8VmQdC29Ov85sgFeaBL-WnRf4KXXWd0EXsss5iQOMx09JdTqO72_5RKJv8RWCp26FOBR8ScKNEmJx5WM2wIGLm8MKQsck-_fakRc4AMfdeSOy8eN5H_B5q8scVoCV7nk8JJkmHB0Gl1DjySteQIDyHCVyNInSbdn4orzyGF6WH2wfNUDVtCrHKEgDjMFsmb9JZDFi07rfeNl7JfYvQbmSIE3scN1VIeNxj5ORY0pl5oyw3ehXUYc8";
  const res = (
    await fetch(
      `${BASE_URL}/v4/settings/signatures/appearance/hand_signature/mobile/text`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();

  const body = await res;
  return body;
};

/***
 * name: get_templates
 * objective:   this service API to get list of templates for the current user
 * args: recordPerPage , pageNo
 * return promise response
 */
const get_templates = async (recordPerPage, pageNo) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(
      `${BASE_URL}/v4/settings/templates/${recordPerPage}/${pageNo}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();

  const body = await res;
  return body;
};

/***
 * name: get_profile_picture
 * objective:   use this service API to get profile picture of the current user
 * args: none
 * return promise response
 */
const get_signature_appearance = async (appearanceName) => {
  // const token = JSON.parse(sessionStorage.getItem("password_token"));
  const token =
    "Plj2zOHnvKTf-B5KRkvhtNASJECsJWoJuL-MePP6mWrmWtxzd4q7bb_cyE2AGzH3TFSYbRnUvAIFAFhP2r8vKKiviSluOlPOMZDPnpZPPbEOaH0Ku3HbXrY36FpakUHRkVV3wS2BDrM3YUaDSD8ngKyamG9sR3i5u1fYgXHFuxvC1hW-bzfro_7rHEx8PFI7iTduRhxxFKvBz27Vhwffo3b5nivm2upOVSw8QtOJJTusfwHjiyygPRRbXraC1ay8ueDggEO2UxLK6o0RZa_I7pfosPacAFsVFdtkHHJVCWJE3-Zrs_8HrF8VmQdC29Ov85sgFeaBL-WnRf4KXXWd0EXsss5iQOMx09JdTqO72_5RKJv8RWCp26FOBR8ScKNEmJx5WM2wIGLm8MKQsck-_fakRc4AMfdeSOy8eN5H_B5q8scVoCV7nk8JJkmHB0Gl1DjySteQIDyHCVyNInSbdn4orzyGF6WH2wfNUDVtCrHKEgDjMFsmb9JZDFi07rfeNl7JfYvQbmSIE3scN1VIeNxj5ORY0pl5oyw3ehXUYc8";
  const res = (
    await fetch(
      `${BASE_URL}/v4/settings/signatures/appearance/design/${appearanceName}/preview`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();

  const body = await res;
  return body;
};

/***
 * name: update_initial_appearance
 * objective: use this service API to update the signature appearance when initialling a document of the current user
 * args: default_method => "DRAW" "UPLOAD" "TEXT" required , upload_image optional , text_value optional
 * return promise response
 */
const update_initial_appearance = async (values) => {
  const postData = {
    default_method: values?.initials,
    upload_image: values?.upload_initial_image,
    text_value: values?.itext_value,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(
    `${BASE_URL}/v4/settings/signatures/appearance/initials`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    }
  );

  return res;
};

/***
 * name: update_hand_signature_browser
 * objective: use this service API to update the signature appearance setting used in web browsers for the current user.
 * args: default_method => "DRAW" "UPLOAD" "TEXT" required , upload_image optional , text_value optional
 * return promise response
 */
const update_hand_signature_browser = async (values) => {
  const postData = {
    default_method: values?.hand_signature,
    upload_image: values?.upload_image_web,
    text_value: values?.text_value_web,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(
    `${BASE_URL}/v4/settings/signatures/appearance/browser`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    }
  );

  return res;
};

/***
 * name: update_signature_appearance_design
 * objective: use this service API to update signature appearance design for the current user
 * args: default_method => default_design New design name to be updated for the signature design appearance (optional)
 * return promise response
 */
const update_signature_appearance_design = async (default_design) => {
  const postData = {
    default_design: default_design,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(
    `${BASE_URL}/v4/settings/signatures/appearance/design`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    }
  );

  return res;
};

/***
 * name: update_hand_signature
 * objective: use this service API to update the signature appearance setting used in mobile browsers for the current user
 * args: default_method => "DRAW" "UPLOAD" "TEXT" required , upload_image optional , text_value optional
 * return promise response
 */
const update_hand_signature_mobile = async (values) => {
  const postData = {
    default_method: values?.hand_msignature,
    upload_image: values?.upload_image,
    text_value: values?.hand_mtext_value,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(
    `${BASE_URL}/v4/settings/signatures/appearance/mobile`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    }
  );

  return res;
};

/***
 * name: update_hand_signature
 * objective: use this service API to update the signature appearance setting used in mobile browsers for the current user
 * args: default_method => "DRAW" "UPLOAD" "TEXT" required , upload_image optional , text_value optional
 * return promise response
 */
const update_signature_settings_metadata = async (values) => {
  const postData = {
    signing_reason: values?.signing_reason,
    contact_information: values?.contact,
    signing_location: values?.location,
    commitment_type: values?.commitment_type,
  };

  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(`${BASE_URL}/v4/settings/signatures/metadata`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

/***
 * name: remove_account_request
 * objective: This API will trigger the account deletion confirmation email against the requested user
 * args: none
 * return promise response
 */
const remove_account_request = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {};
  const res = await fetch(`${BASE_URL}/v4/account/remove`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

const account_password_recover = async (email) => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const postData = {
    user_email: email,
  };
  const res = await fetch(`${BASE_URL}/v4/account/password/recover`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

/***
 * name: remove_account
 * objective: use this service API to delete an account against which a "Remove Account Request" service API was initiated,
 * args: q
 * return promise response
 */
const remove_account = async (q) => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const postData = {
    q: q,
  };
  const res = (
    await fetch(`${BASE_URL}/v4/account/remove`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name: get_general_profile_information
 * objective: use this service API to get the general profile information of the current user
 * args: none
 * return promise response
 */
const get_general_profile_information = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name: update_general_profile_information
 * objective:   use this service API to update general profile information of the current use
 * args: username is required and others is optional
 * return promise response
 */
const update_general_profile_information = async (
  userName,
  job_title,
  company_name,
  mobile_number,
  country,
  time_zone,
  language,
  user_national_id
) => {
  const postData = {
    user_name: userName,
    job_title: job_title, //optional
    company_name: company_name, //optional
    mobile_number: mobile_number, //optional
    country: country, //optional
    time_zone: time_zone, //optional
    language: language, //optional
    user_national_id: user_national_id, //optional
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/profile/general`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

/***
 * name: get_profile_picture
 * objective:   use this service API to get profile picture of the current user
 * args: none
 * return promise response
 */
const get_profile_picture = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/profile/general/photo`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name: update_locale_settings
 * objective: this service API to update locale profile information of the current user
 * args: country , timezone , language
 * return promise response
 */
const update_locale_settings = async (country, timezone, language) => {
  const postData = {
    country: country,
    timezone: timezone,
    language: language,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/profile/locale`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

/***
 * name: update_security_settings
 * objective:  this service API to update profile’s security information of the current user
 * args: password , question , answer
 * return promise response
 */
const update_security_settings = async (password, question, answer) => {
  const postData = {
    password: password,
    question: question,
    answer: answer,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/profile/security`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

const get_active_sessions = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/account/active-sessions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  return res;
};

const get_authorised_devices = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/account/authorised-devices`, {
      method: "GEt",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  return res;
};

//https://api.signinghub.com/v4/account/two-factor-authentication

const update_two_factor_auth = async (totp) => {
  const postData = {
    totp: totp,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/account/two-factor-authentication`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/account/two-factor-authentication

const disable_two_factor_auth = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/account/two-factor-authentication`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};
//https://api.signinghub.com/v4/account/two-factor-authentication/recovery-codes

export const regenrate__recovery_code_two_factor_auth = async (totp) => {
  const postData = {
    totp: totp,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(
    `${BASE_URL}/v4/account/two-factor-authentication/recovery-codes`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    }
  );

  return res;
};
//https://api.signinghub.com/v4/settings/notifications

export const get_notifications_Settengs = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/notifications`, {
      method: "GEt",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  return res;
};

//https://api.signinghub.com/v4/settings/notifications
const update_notifications_settings = async (
  templates_limit_reached_screen,
  templates_limit_reached_email,
  signature_limit_reached_screen,
  signature_limit_reached_email,
  workflow_limit_reached_screen,
  workflow_limit_reached_email,
  simple_electronic_signature_limit_reached_screen,
  simple_electronic_signature_limit_reached_email,
  serviceplan_reset,
  serviceplan_change,
  online_payment,
  forgot_password,
  password_changed_screen,
  password_changed_email,
  login_failed,
  document_processed_by_others_screen,
  document_processed_by_others_email,
  document_processed_by_others_push,
  document_completed_screen,
  document_completed_email,
  document_completed_push,
  daily_summary,
  document_subsequent_changes_screen,
  document_subsequent_changes_email,
  document_subsequent_changes_push,
  process_document,
  recall_document_recipients,
  recall_document_screen,
  recall_document_email,
  share_document_screen,
  share_document_email,
  share_document_push
) => {
  const postData = {
    notification_setting: {
      templates_limit_reached: {
        on_screen: {
          enabled: templates_limit_reached_screen,
        },
        email: {
          enabled: templates_limit_reached_email,
        },
      },
      signature_limit_reached: {
        on_screen: {
          enabled: signature_limit_reached_screen,
        },
        email: {
          enabled: signature_limit_reached_email,
        },
      },
      workflow_limit_reached: {
        on_screen: {
          enabled: workflow_limit_reached_screen,
        },
        email: {
          enabled: workflow_limit_reached_email,
        },
      },
      simple_electronic_signature_limit_reached: {
        on_screen: {
          enabled: simple_electronic_signature_limit_reached_screen,
        },
        email: {
          enabled: simple_electronic_signature_limit_reached_email,
        },
      },
      serviceplan_reset: {
        on_screen: {
          enabled: serviceplan_reset,
        },
      },
      serviceplan_change: {
        on_screen: {
          enabled: serviceplan_change,
        },
      },
      online_payment: {
        on_screen: {
          enabled: online_payment,
        },
      },
      forgot_password: {
        on_screen: {
          enabled: forgot_password,
        },
      },
      password_changed: {
        on_screen: {
          enabled: password_changed_screen,
        },
        email: {
          enabled: password_changed_email,
        },
      },
      login_failed: {
        on_screen: {
          enabled: login_failed,
        },
      },
      document_processed_by_others: {
        on_screen: {
          enabled: document_processed_by_others_screen,
        },
        email: {
          enabled: document_processed_by_others_email,
        },
        push: {
          enabled: document_processed_by_others_push,
        },
      },
      document_completed: {
        on_screen: {
          enabled: document_completed_screen,
        },
        email: {
          enabled: document_completed_email,
        },
        push: {
          enabled: document_completed_push,
        },
      },
      daily_summary: {
        email: {
          enabled: daily_summary,
        },
      },
      document_subsequent_changes: {
        on_screen: {
          enabled: document_subsequent_changes_screen,
        },
        email: {
          enabled: document_subsequent_changes_email,
        },
        push: {
          enabled: document_subsequent_changes_push,
        },
      },
      process_document: {
        email: {
          enabled: process_document,
        },
      },
      recall_document: {
        recipients: recall_document_recipients,
        on_screen: {
          enabled: recall_document_screen,
        },
        email: {
          enabled: recall_document_email,
        },
      },
      share_document: {
        on_screen: {
          enabled: share_document_screen,
        },
        email: {
          enabled: share_document_email,
        },
        push: {
          enabled: share_document_push,
        },
      },
    },
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/notifications`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/settings/notifications/emails

const get_email_Settings = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/notifications/emails`, {
      method: "GEt",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  return res;
};

//https://api.signinghub.com/v4/settings/notifications/emails

const update_email_settings = async (type, subject, body) => {
  const postData = {
    type: type,
    subject: subject,
    body: body,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/notifications/emails`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

/***
 * name: reset_user_emails_to_default
 * objective:   reset user emails to default
 * args: none
 * return promise response
 */
const reset_user_emails_to_default = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/notifications/email/reset`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

//https://api.signinghub.com/v4/enterprise/library/{recordPerPage}/{pageNo}

const get_enterprise_library_documents = async (recordPerPage, pageNo) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  let headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const res = (
    await fetch(
      `${BASE_URL}/v4/enterprise/library/${recordPerPage}/${pageNo}`,
      {
        method: "GEt",
        headers: headers,
      }
    )
  ).json();
  return res;
};

//https://api.signinghub.com/v4/settings/library/{id}

const get_personal_library_document = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/library/${id}`, {
      method: "GEt",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  return res;
};

//https://api.signinghub.com/v4/settings/library/{id}
const update_personla_library_document = async (
  id,
  document_name,
  folder_name,
  template_name
) => {
  const postData = {
    document_name: document_name,
    folder_name: folder_name,
    template_name: template_name,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/library/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/settings/library/{id}
const delate_personal_library_document = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/library/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

//https://api.signinghub.com/v4/settings/library

const add_personla_library_document = async (
  file,
  document_name,
  folder_name,
  template_name
) => {
  const postData = {
    file: file,
    folder_name: folder_name,
    template_name: template_name,
  };
  const formData = new FormData();

  for (const name in postData) {
    formData.append(name, postData[name]);
  }

  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/library`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-file-name": "Test.jpg",
    },
    body: formData,
  });

  return res;
};

//https://api.signinghub.com/v4/settings/library/bulk
//arr_id = [int]
const bulk_delete_personla_library_document = async (arr_id) => {
  const postData = arr_id;
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/library/bulk`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/settings/library/folders

const get_personal_library_folders = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/library/folders`, {
      method: "GEt",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  return res;
};

//https://api.signinghub.com/v4/enterprise/library/folders
const get_enterprise_library_folders = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/enterprise/library/folders`, {
      method: "GEt",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  return res;
};

//https://api.signinghub.com/v4/settings/library/folders
const add_personla_library_folder = async (folder_name) => {
  const postData = {
    folder_name: folder_name,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/library/folders`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/enterprise/library/folders
const add_enterprise_library_folder = async (folder_name) => {
  const postData = {
    folder_name: folder_name,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/enterprise/library/folders`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};
//https://api.signinghub.com/v4/settings/library/folders/{id}

const get_personal_library_folder = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/library/folders/${id}`, {
      method: "GEt",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  return res;
};

//https://api.signinghub.com/v4/settings/library/folders/{id}
const update_personla_library_folder = async (folder_name, id) => {
  const postData = {
    folder_name: folder_name,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/library/folders/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/enterprise/library/folders/{id}
const update_enterprise_library_folder = async (folder_name, id) => {
  const postData = {
    folder_name: folder_name,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/enterprise/library/folders/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

const reset_gatekeeper_settings = async () => {
  const postData = {
    gatekeeper_setting: {
      enabled: false,
      gatekeeper: {
        user_email: null,
        user_name: null,
      },
    },
  };
  try {
    const token = JSON.parse(sessionStorage.getItem("password_token"));
    const res = await fetch(`${BASE_URL}/v4/settings/delegate`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });
    return res;
  } catch (err) {
    throw err;
  }
};

//https://api.signinghub.com/v4/settings/library/folders/{id}
const delete_personla_library_folder = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/library/folders/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

//https://api.signinghub.com/v4/enterprise/library/folders/{id}
const delete_enterprise_library_folder = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/enterprise/library/folders/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

//https://api.signinghub.com/v4/settings/library/folders/bulk
const bulk_delete_personla_library_folder = async (arr_id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  //const postData = arr_id;
  const res = await fetch(
    `${BASE_URL}/https://api.signinghub.com/v4/settings/library/folders/bulk`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res;
};

//https://api.signinghub.com/v4/settings/library/move
//ids [int] Ids of library documents.
//folder_name Name of the library document folder.

const move_personal_library_documents = async (ids, folder_name) => {
  const postData = {
    ids: ids,
    folder_name: folder_name,
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/library/move`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

/***
 * name: get_personal_legal_notice
 * objective:use this service API to get a personal Legal Notice for Users
 * args: id
 * return promise response
 */
const get_personal_legal_notice = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    id: id,
  };
  const res = (
    await fetch(`${BASE_URL}/v4/settings/legal_notices/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    })
  ).json();

  const body = await res;
  return body;
};
/***
 * name: update_personal_legal_notice
 * objective:use this service API to edit a personal Legal Notice for Users
 * args: id , title , content
 * return promise response
 */
const update_personal_legal_notice = async (id, postData) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(`${BASE_URL}/v4/settings/legal_notices/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

/***
 * name: delete_personal_legal_notice
 * objective:use this service API to delete a personal Legal Notice for Users.
 * args: id
 * return promise response
 */
const delete_personal_legal_notice = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(`${BASE_URL}/v4/settings/legal_notices/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

/***
 * name: add_personal_legal_notice
 * objective:use this service API to add a personal Legal Notice for Users
 * args: title , content
 * return promise response
 */
const add_personal_legal_notice = async (postData) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(`${BASE_URL}/v4/settings/legal_notices`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/settings/legal_notices/bulk
const bulk_delete_personal_legal_notice = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(`${BASE_URL}/v4/settings/legal_notices/bulk`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

/***
 * name: add_personal_group
 * objective:use this service API to add a personal group for Users
 * args: Name (Required) , content (Optional) ,
 *  Members(Required) [
 * {
 *   user_email(Required),
 *   user_name(Required)
 * }
 * ]
 * return promise response
 */
const add_personal_group = async (Name, Description, Members) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    Name: Name,
    Description: Description,
    Members: Members,
  };
  console.log(postData);
  const res = await fetch(`${BASE_URL}/v4/settings/groups`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};
/***
 * name: update_personal_group
 * objective:use this service API to update a personal group for Users
 * args: Name (Required) , content (Optional) ,
 *  Members(Required) [
 * {
 *   user_email(Required),
 *   user_name(Required)
 * }
 * ]
 * return promise response
 */
const update_personal_group = async (id, Name, Description, Members) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    Name: Name,
    Description: Description,
    Members: Members,
  };
  const res = await fetch(`${BASE_URL}/v4/settings/groups/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });
  return res;
};

/***
 * name: delete_personal_group
 * objective:use this service API to delete a personal group for Users
 * args: id
 * return promise response
 */
const delete_personal_group = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/groups/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

//https://api.signinghub.com/v4/enterprise/groups/{id}
const delete_enterprise_group = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/enterprise/groups/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

/***
 * name: get_personal_group
 * objective:use this service API to get personal group for its users
 * args: id
 * return promise response
 */
const get_personal_group = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/groups/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

//https://api.signinghub.com/v4/settings/groups/bulk
const bulk_delete_personal_group = async (arr_id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = arr_id;
  const res = await fetch(`${BASE_URL}/v4/settings/groups/bulk`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};
/***
 * name: get_legal_notices
 * objective:use this service API to get the list of legal notices for the current user
 * args: recordPerPage , pageNo
 * return promise response
 */
const get_legal_notices = async (params) => {
  const { recordPerPage, pageNo, searchText, searchEnterprise } = params;

  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(
      `${BASE_URL}/v4/settings/legal_notices/${recordPerPage}/${pageNo}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          ...(searchText && { "x-search-text": searchText }),
          ...(searchEnterprise && { "x-enterprise": searchEnterprise }),
        },
      }
    )
  ).json();

  const body = await res;
  return body;
};

//https://api.signinghub.com/v4/settings/cloud-drives

const get_cloud_drives_settings = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/cloud-drives`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};
//https://api.signinghub.com/v4/settings/cloud-drives

const enable_cloud_drives_settings = async (redirect_uri, code, type) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    redirect_uri: redirect_uri,
    code: code,
    type: type,
  };
  const res = (
    await fetch(`${BASE_URL}/v4/settings/cloud-drives`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    })
  ).json();

  const body = await res;
  return body;
};

//https://api.signinghub.com/v4/settings/cloud-drives/{type}
//type 0 , 1 , 2 DROPBOX | ONEDRIVE | GOOGLE_DRIVE.
const disable_cloud_drives_settings = async (type) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = (
    await fetch(`${BASE_URL}/v4/settings/cloud-drives/${type}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name: add_contact
 * objective: use this service API to add a contact for the current user
 * args: user_name , user_email
 * return promise response
 */
const add_contact = async (postData) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/contacts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

const add_enterprise_contact = async (postData) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/enterprise/contacts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/settings/contacts/{id}

const delete_personal_contact = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/contacts/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
//https://api.signinghub.com/v4/enterprise/contacts/{id}
const delete_enterprise_contact = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/enterprise/contacts/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

//https://api.signinghub.com/v4/settings/contacts/{id}
const get_personal_contatc = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/contacts/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

//https://api.signinghub.com/v4/settings/contacts/{id}

const update_personal_contact = async (id, user_name) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    user_name: user_name,
  };
  const res = await fetch(`${BASE_URL}/v4/settings/contacts/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};
//https://api.signinghub.com/v4/enterprise/contacts/{id}
const update_enterprise_contact = async (id, user_name) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    user_name: user_name,
  };
  const res = await fetch(`${BASE_URL}/v4/enterprise/contacts/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/settings/contacts/bulk
const bulk_delete_personal_contact = async (arr_id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = arr_id;
  const res = await fetch(`${BASE_URL}/v4/settings/contacts/bulk`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/settings/contacts/bulk

const bulk_import_personal_contact = async (csv_file) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    file: csv_file,
  };
  var formBody = [];
  for (var property in postData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(postData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  const res = await fetch(`${BASE_URL}/v4/settings/contacts/bulk`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: formBody,
  });

  return res;
};

/***
 * name: get_contacts
 * objective:use this service API to get a list of contacts for the current user
 * args: recordPerPage , pageNo
 * return promise response
 */
const get_contacts = async (recordPerPage, pageNo) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/contacts/${recordPerPage}/${pageNo}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

const reset_signature_delegation_settings = async ({ all }) => {
  const postData = {
    enabled: false,
    delegate: [],
  };

  if (all) {
    postData.gatekeeper_setting = {
      enabled: false,
      gatekeeper: {
        user_email: null,
        user_name: null,
      },
    };
  }
  try {
    const token = JSON.parse(sessionStorage.getItem("password_token"));
    const res = await fetch(`${BASE_URL}/v4/settings/delegate`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const update_full_delegation_settings = async ({
  delegateList,
  gatekeeper,
}) => {
  const postData = {
    enabled: true,
    delegate: delegateList,
    gatekeeper_setting: {
      enabled: true,
      gatekeeper: {
        user_name: gatekeeper.user_name,
        user_email: gatekeeper.user_email,
      },
    },
  };

  try {
    const token = JSON.parse(sessionStorage.getItem("password_token"));
    const res = await fetch(`${BASE_URL}/v4/settings/delegate`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });
    return res;
  } catch (err) {
    throw err;
  }
};
const update_signature_gatekeeper_settings = async ({ gatekeeper }) => {
  const postData = {
    gatekeeper_setting: {
      enabled: true,
      gatekeeper: {
        user_email: gatekeeper.user_email,
        user_name: gatekeeper.user_name,
      },
    },
  };
  try {
    const token = JSON.parse(sessionStorage.getItem("password_token"));
    const res = await fetch(`${BASE_URL}/v4/settings/delegate`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });
    return res;
  } catch (err) {
    throw err;
  }
};
// https://api.signinghub.com/v4/system/configurations/timezones
const get_time_zone = async () => {
  try {
    const token = JSON.parse(sessionStorage.getItem("password_token"));
    const res = await fetch(`${BASE_URL}/v4/system/configurations/timezones`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
    if (res.ok) {
      const body = await res.json();
      return body;
    } else {
      console.log("`Failed to fetch timezones.");
    }
  } catch (err) {
    throw err;
  }
};

export {
  get_time_zone,
  update_full_delegation_settings,
  update_signature_gatekeeper_settings,
  reset_signature_delegation_settings,
  get_groups,
  get_library_documents,
  change_password,
  get_signature_delegation_settings,
  update_signature_delegation_settings,
  get_general_profile_information,
  get_general_profile_picture,
  update_profile_picture,
  get_hand_signature_text,
  get_initials_for_upload_option,
  get_hand_signature_upload_for_mobile,
  get_hand_signature_upload_for_web,
  get_signature_settings,
  get_initials_for_text_option,
  get_hand_signature_text_for_mobile,
  get_templates,
  get_profile_picture,
  get_signature_appearance,
  reset_user_emails_to_default,
  update_locale_settings,
  update_security_settings,
  update_initial_appearance,
  update_hand_signature_browser,
  update_signature_appearance_design,
  update_hand_signature_mobile,
  update_signature_settings_metadata,
  remove_account_request,
  remove_account,
  get_personal_legal_notice,
  update_personal_legal_notice,
  delete_personal_legal_notice,
  add_personal_legal_notice,
  add_personal_group,
  update_personal_group,
  update_general_profile_information,
  delete_personal_group,
  delete_enterprise_group,
  get_personal_group,
  get_legal_notices,
  get_contacts,
  add_contact,
  add_enterprise_contact,
  update_signature_settings,
  get_active_sessions,
  get_authorised_devices,
  update_two_factor_auth,
  disable_two_factor_auth,
  update_notifications_settings,
  get_email_Settings,
  update_email_settings,
  get_enterprise_library_documents,
  get_personal_library_document,
  update_personla_library_document,
  delate_personal_library_document,
  add_personla_library_document,
  bulk_delete_personla_library_document,
  get_personal_library_folders,
  add_personla_library_folder,
  get_enterprise_library_folders,
  add_enterprise_library_folder,
  get_personal_library_folder,
  update_personla_library_folder,
  delete_personla_library_folder,
  delete_enterprise_library_folder,
  update_enterprise_library_folder,
  bulk_delete_personla_library_folder,
  move_personal_library_documents,
  bulk_delete_personal_legal_notice,
  bulk_delete_personal_group,
  get_cloud_drives_settings,
  enable_cloud_drives_settings,
  disable_cloud_drives_settings,
  delete_personal_contact,
  delete_enterprise_contact,
  get_personal_contatc,
  update_personal_contact,
  update_enterprise_contact,
  bulk_delete_personal_contact,
  bulk_import_personal_contact,
  account_password_recover,
  reset_gatekeeper_settings,
};
