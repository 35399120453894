import { Button, Layout, Row, Steps } from "antd";
import {
  Outlet,
  RouterProvider,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";
import { PathConstants } from "../constants/paths";
import WorkFlowSteps from "../components/WorkFlowSteps";
import WorkFlowFooterAction from "../components/WorkFlowFooterAction";
import useApiRequest from "../services/hooks/useFetch";
import CancelUploadModal from "../pages/documents/components/CancelUploadModal";


export default function DocumentsLayout() {
  const navigate = useNavigate();
  const [cancelModalVisiable , setCancelModalVisiable] = useState(false);
  const { packageId } = useParams();

  const api = useApiRequest();
  const { Mutate } = api;
  const { isLoading, mutate: finish_document_mutation } = Mutate(
    "finish_document",
    "POST"
  );

  const { pathname } = useLocation();
  const [nextFn, setNextFn] = useState();
  const [nextTxt, setNextTxt] = useState();
  const [backBtnTxt, setBackTxt] = useState();
  const [backBtnFn, setBackFn] = useState();
  const [draftTxt, setDraftTxt] = useState();
  const [draftFn, setDraftFn] = useState();


 
  useEffect(() => {
    const package_id = JSON.parse(sessionStorage.getItem("package_id"));
    switch (pathname) {
      case PathConstants.Documents:
        setNextFn(() => upload_documents);
        setNextTxt(() => t("submit_upload"));
        setBackTxt(() => t("cancel_upload"));
        setDraftTxt(() => t("save_draft"));
        setDraftFn(() => {});
        setBackFn(() => cancel_upload);
        break;
      case PathConstants.Recipients.replace(":packageId", package_id):
        setNextFn(() => go_to_viewer);
        setNextTxt(() => t("save_prepare"));
        setBackTxt(() => t("back_to_upload"));
        setDraftTxt(() => t("save_draft"));
        setDraftFn(() => {});
        setBackFn(() =>  goBack);
        break;
      case PathConstants.Sign.replace(":packageId", packageId):
        setNextFn(() => upload_documents);
        setNextTxt(() => t("Reminder_All"));
        setBackTxt(() => t("Back_to_All_Documents"));
        setDraftTxt(() => t("Save_as_Template"));
        setDraftFn(() => save_documents_to_draft);
        setBackFn(() =>  goBack);
        break;
      default:
        setNextFn(() => send_document);
        setNextTxt(() => t("send"));
        setBackTxt(() => t("back_to_recipients"));
        setDraftTxt(() => t("save_draft"));
        setDraftFn(() => back_to_recipients);
        setBackFn(() =>  goBack);
        break;
    }
  }, [pathname]);

  const goBack = () => {
    navigate(-1);
  }

  const { t } = useTranslation("translation", { keyPrefix: "languages" });
  const package_id = JSON.parse(sessionStorage.getItem("package_id"));

  const upload_documents = () => {
    navigate(PathConstants.Recipients.replace(":packageId", package_id));
  };

  const go_to_viewer = () => {
    const package_id = JSON.parse(sessionStorage.getItem("package_id"));
   
    navigate(PathConstants.Prepare.replace(":packageId", packageId));
  };

  const save_documents_to_draft = (outletFunction) => {};

  const send_document = () => {
    const params = {
      url: `packages/${packageId}/workflow`,
    };
    sessionStorage.removeItem('recipients');
    sessionStorage.removeItem('uploadedList');
    sessionStorage.removeItem('newDocs');
    sessionStorage.removeItem('package_id');
    sessionStorage.removeItem('fileList');
    
    
    finish_document_mutation(params, {
      onSuccess: async (data) => {
        if (data.ok) {
          const res = await data.json();
          navigate(PathConstants.Send.replace(":packageId", res[0].package_id));
        }
      },
    });
  };
  const back_to_recipients = () => {
    navigate(-1);
  };

  const cancel_upload = () => {
    setCancelModalVisiable(() => true);
  }
  return (  
    <Layout
      className={`flex flex-col min-h-[90vh] bg-[#fff] ${
        pathname.includes("prepare") || pathname.includes("sign")
          ? "gap-0"
          : "gap-4"
      }`}
      style={{ minHeight: "calc(100vh - 140px)", background: "#fff" }}
    >
      {!pathname.includes("sign") && <WorkFlowSteps t={t} />}
      <Outlet />
      {!pathname.includes("send") && (
        <WorkFlowFooterAction
          t={t}
          nextBtnText={nextTxt}
          nextBtnFn={nextFn}
          drftBtnText={draftTxt}
          draftBtnFn={draftFn}
          backBtnText={backBtnTxt}
          backBtnFn={backBtnFn}
          pathName={pathname}
          isLoading={isLoading}
        />
      )}
      {
        cancelModalVisiable && <CancelUploadModal cancelModalVisiable={cancelModalVisiable} 
        setCancelModalVisiable={setCancelModalVisiable} t={t}/>
      }
    </Layout>
  );
}
