import { BASE_URL } from "../../global";

/**
 *  name: oTP_verification
 *  objective: use this service API to verify an OTP, generated using OTP Login Authentication.
 *  args: none
 *  return: promise response
 *  document_status : Enum: "ALL" "DRAFT" "INPROGRESS" "PENDING" "SIGNED" "COMPLETED" "REVIEWED" "DECLINED" "EDITED" "EXPIRING_IN_SEVEN_DAYS"
 */
const get_all_packages = async (document_status, pageNo, recordPerPage) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(
      `${BASE_URL}/v4/packages/${document_status}/${pageNo}/${recordPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();

  const body = await res;
  return body;
};

//https://api.signinghub.com/v4/packages/{packageId}
export const delete_package = async (packageId) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/packages/${packageId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

export const recall_package = async (packageId) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/packages/${packageId}/workflow`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

export { get_all_packages };
