import { Button, Modal, message, notification } from "antd";
import { useEffect, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { delete_document } from "../../../services/api/document/package/scripts";
import { delete_package } from "../../../services/api/Packages/scripts";
import { useNavigate } from "react-router";
import { PathConstants } from "../../../constants/paths";

const CancelUploadModal = ({setCancelModalVisiable , cancelModalVisiable , t}) => {
    const [loading , setLoading] = useState(false);
    const navigate = useNavigate();

    const submitHandler = async() => {
      setLoading(() => true);
       try{
        const id = JSON.parse(sessionStorage.getItem("package_id"));
             const res = await delete_package(id);
             if (res.status === 200) {
                let msg = "Package Upload Canceled Successfully ";
                message.success({ content: msg });
                sessionStorage.removeItem('recipients');
                sessionStorage.removeItem('uploadedList');
                sessionStorage.removeItem('newDocs');
                sessionStorage.removeItem('package_id');
                sessionStorage.removeItem('fileList');
                navigate(PathConstants.Home);
                
              } else {
                const body = await res.json();
                let msg = body.Message ;
                message.error({ content: msg });
              }
       } catch(err){
        
        message.error({ content: err.message });
       }
    }
    return (
        <Modal
          width={700}
          height={450}
          title={<p className="text-xl sm:text-xxl">{t("cancel_doc_title")}</p>}
          open={cancelModalVisiable}
          okButtonProps={{
            style: { display: "none" },
          }}
          cancelButtonProps={{ style: { display: "none" } }}
          closeIcon={
            <CloseCircleOutlined onClick={() => setCancelModalVisiable(false)} />
          }
        >
          <p className="text-center text-14 font-medium leading-normal  sm:pt-2 pb-6">
            <span>{t("confirm_cancel_document_message")}</span>
          </p>
    
          <div className="flex w-[80%] justify-between m-auto">
            <Button
              type="primary"
              className="w-[195px]"
              onClick={() => setCancelModalVisiable(false)}
            >
              {t("cancel_btn")}
            </Button>
            <Button
              className="w-[285px]"
              onClick={() => submitHandler()}
              loading={loading}
            >
              {t("delete_btn")}
            </Button>
          </div>
        </Modal>
      );
}

export default CancelUploadModal;
