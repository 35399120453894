import { Navigate, createBrowserRouter } from "react-router-dom";
import { EnterprisePaths, PathConstants } from "./paths";
import { PagesConstants } from "./pages";
import MainLayout from "../layouts/MainLayout";
import AuthLayout from "../layouts/AuthLayout";
import DocumentsLayout from "../layouts/DocumentsLayout";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import ProtectedRoute from "../layouts/ProtectedRoute";
import ProtectedLoginRoute from "../layouts/ProtectedLoginRoute";
import SettingLayout from "../layouts/SettingLayout";
import EnterpriseSettingsLayout from "../layouts/EnterpriseSettingsLayout";

const password_token = sessionStorage.getItem("password_token");

export const MainRouter = createBrowserRouter([
  {
    element: <ProtectedRoute />,
    path: PathConstants.Home,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <PagesConstants.Home />,
        index: true,
      },
      {
        path: PathConstants.Documents,
        element: <DocumentsLayout />,
        children: [
          {
            element: <PagesConstants.Documents />,
            index: true,
          },
          {
            path: PathConstants.Prepare,
            element: <PagesConstants.Prepare />,
          },
          {
            path: PathConstants.Recipients,
            element: <PagesConstants.Recipients />,
          },
          {
            path: PathConstants.Send,
            element: <PagesConstants.Send />,
          },
          {
            path: PathConstants.Sign,
            element: <PagesConstants.Sign />,
          },
          {
            path: PathConstants.ViewDoc,
            element: <PagesConstants.ViewDoc />,
          },
        ],
      },
      {
        path: PathConstants.Templates,
        element: <PagesConstants.Templates />,
      },
      {
        path: PathConstants.Library,
        element: <PagesConstants.Library />,
      },
      {
        path: PathConstants.Contacts,
        element: <PagesConstants.Contacts />,
      },
      {
        path: PathConstants.Groups,
        element: <PagesConstants.Groups />,
      },
      {
        path: PathConstants.Contact_us,
        element: <PagesConstants.ContactUs />,
      },
      {
        path: PathConstants.Activity,
        element: <PagesConstants.Activity />,
      },
      {
        path: PathConstants.Settings,
        element: <SettingLayout />,
        children: [
          {
            element: <PagesConstants.ProfileSettings />,
            index: true,
          },
          {
            path: PathConstants.SignatureSettings,
            element: <PagesConstants.SignatureSettings />,
          },
          {
            path: PathConstants.NotificationSettings,
            element: <PagesConstants.NotificationSettings />,
          },
          {
            path: PathConstants.CloudStorageSettings,
            element: <PagesConstants.CloudStorageSettings />,
          },
          {
            path: PathConstants.LegalNoticeSettings,
            element: <PagesConstants.LegalNoticeSettings />,
          },
          {
            path: PathConstants.DelegateSettengs,
            element: <PagesConstants.DelegateSettengs />,
          },
          {
            path: PathConstants.DeleteAccountSettengs,
            element: <PagesConstants.DeleteAccountSettengs />,
          },
        ],
      },
      {
        element: <EnterpriseSettingsLayout />,
        path: "/enterprise",
        children: [
          {
            element: <PagesConstants.ProfileSettings />,
            path: "profile",
          },
          {
            path: EnterprisePaths.Users,
            element: <PagesConstants.EnterpriseUsers />,
          },
          {
            path: EnterprisePaths.Notifications,
            element: <PagesConstants.NotificationSettings />,
          },
          {
            path: EnterprisePaths.Integrations,
            element: <PagesConstants.EnterpriseIntegrations />,
          },
          {
            path: EnterprisePaths.Branding,
            element: <PagesConstants.EnterpriseBranding />,
          },
          {
            path: EnterprisePaths.Reports,
            element: <PagesConstants.EnterpriseReports />,
          },
          {
            path: EnterprisePaths.Advanced,
            element: <PagesConstants.AdvancedEnterpriseSettings />,
          },
          // Add more enterprise settings pages as needed
        ],
      },
    ],
  },
  {
    element: <ProtectedLoginRoute />,
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        path: PathConstants.Login,
        element: <PagesConstants.Login />,
      },
      {
        path: PathConstants.SignUp,
        element: <PagesConstants.SignUp />,
      },
      {
        path: PathConstants.terms,
        element: <PagesConstants.Terms />,
      },
      {
        path: PathConstants.Privacy,
        element: <PagesConstants.Privacy />,
      },
    ],
  },
]);
