import profileImg from "../assets/images/profile-drawer.svg";
import signatureImg from "../assets/images/signatureDrawer.svg";
import notificationImg from "../assets/images/notificationDrawer.svg";
import cloudImg from "../assets/images/cloudDrawer.svg";
import legalImg from "../assets/images/logalDrawer.svg";
import delegateImg from "../assets/images/settingsDrawer.svg";
import deleteImg from "../assets/images/deleteDrawer.svg";
import logoutImg from "../assets/images/logoutDrawer.svg";
import { Layout, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function SettingLayout() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const items = [
    {
      key: "/settings",
      icon: <img src={profileImg} alt="profileImg" className="w-[20px]" />,
      label: "Profile",
      style: {
        display: "flex",
      },
    },
    {
      key: "/settings/signature",
      icon: <img src={signatureImg} alt="profileImg" className="w-[20px]" />,
      label: "Signature",
      style: {
        display: "flex",
      },
    },
    {
      key: "/settings/notification",
      icon: <img src={notificationImg} alt="profileImg" className="w-[20px]" />,
      label: "Notification Settings",
      style: {
        display: "flex",
      },
    },
    {
      key: "/settings/cloudStorage",
      icon: <img src={cloudImg} alt="profileImg" className="w-[20px]" />,
      label: "Cloud Storage",
      style: {
        display: "flex",
      },
    },
    {
      key: "/settings/legalNotice",
      icon: <img src={legalImg} alt="profileImg" className="w-[20px]" />,
      label: "Legal Notices",
      style: {
        display: "flex",
      },
    },
    {
      key: "/settings/delegate",
      icon: <img src={delegateImg} alt="profileImg" className="w-[20px]" />,
      label: "Delegate Settings",
      style: {
        display: "flex",
      },
    },

    {
      key: "/settings/deleteAccount",
      icon: <img src={deleteImg} alt="profileImg" className="w-[20px]" />,
      label: <p className="text-[#FF1F00]">Delete Account</p>,
      style: {
        display: "flex",
      },
    },
    {
      key: "8",
      icon: <img src={logoutImg} alt="profileImg" className="w-[20px]" />,
      label: "Logout",
      style: {
        display: "flex",
        marginTop: "auto",
      },
    },
  ];

  const onClick = (e) => {
    console.log("Onclick from setting layout :", { e });
    // switch (e.key) {
    //   case "/settings":
    //     navigate("/settings");
    //     break;
    //   case "/settings/signature":
    //     navigate("/settings/signature");
    //     break;
    //   case "/settings/notification":
    //     navigate("/settings/notification");
    //     break;
    //   case "/settings/cloudStorage":
    //     navigate("/settings/cloudStorage");
    //     break;
    //   case "/settings/legalNotice":
    //     navigate("/settings/legalNotice");
    //     break;
    //   case "/settings/delegate":
    //     navigate("/settings/delegate");
    //     break;
    //   case "/settings/deleteAccount":
    //     navigate("/settings/deleteAccount");
    //     break;
    //   default:
    //     break;
    // }
    navigate(e?.key || "/settings");
  };
  return (
    <Layout className="flex-row max-h-[85vh] w-full p-[3vh] gap-10 overflow-scroll-hidden ">
      <Menu
        onClick={onClick}
        style={{
          width: "20vw",
          display: "flex",
          flexDirection: "column",
          paddingTop: "20px",
          paddingBottom: "20px",
        }}
        defaultSelectedKeys={pathname}
        mode="vertical"
        items={items}
      />
      <div className="p-[2em]  rounded-lg w-full overflow-y-scroll hide__scroll ">
        <Outlet />
      </div>
    </Layout>
  );
}

export default SettingLayout;
