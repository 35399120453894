import { Button, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { PathConstants } from "../constants/paths";
import { useMediaQuery } from "react-responsive";
const WorkFlowFooterAction = ({
  t,
  nextBtnText,
  nextBtnFn,
  drftBtnText,
  draftBtnFn,
  backBtnText,
  backBtnFn,
  pathName,
  isLoading,
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 850 });
  const [uploadCount, setUploadCount] = useState(0);
  useEffect(() => {
    setInterval(() => {
      setUploadCount(() => JSON.parse(sessionStorage.getItem("uploadedList")));
    }, 100);
  }, []);

  return (
    <div
      className={
        isMobile
          ? "flex items-center justify-evenly bg-[#F8F8F8] h-14 w-full p-2"
          : "flex justify-between items-center bg-[#F8F8F8] h-[104px] mt-auto pl-[10%] pr-[10%]"
      }
    >
      {isMobile ? (
        ""
      ) : (
        <Button
          type="link"
          onClick={() => backBtnFn()}
          icon={<LeftOutlined className="ml-[10px]" />}
        >
          <span
            className={
              isMobile
                ? "text-center text-zinc-900 text-sm font-normal font-['Poppins'] leading-snug"
                : ""
            }
          >
            {backBtnText}{" "}
          </span>
        </Button>
      )}
      {uploadCount > 0 && (
        <Button
          onClick={() => draftBtnFn()}
          className={
            isMobile
              ? "text-center text-zinc-900 text-sm font-normal font-['Poppins'] leading-snug h-[45px] bg-white rounded-[37px] shadow border border-neutral-200 justify-center items-center w-[45%]"
              : "draft_btn_class ml-[auto]"
          }
          type="outline"
        >
          {drftBtnText}
        </Button>
      )}

      <Button
        onClick={() => nextBtnFn()}
        className={
          isMobile
            ? "text-center text-white text-sm font-normal font-['Poppins'] leading-snug h-[45px] bg-white rounded-[37px] shadow border border-neutral-200 justify-center items-center w-[50%]"
            : "next_btn_class ml-[20px]"
        }
        disabled={uploadCount <= 0}
      >
        {pathName === PathConstants.Documents
          ? `${nextBtnText} (${uploadCount} ${t("files")})`
          : nextBtnText}
      </Button>
    </div>
  );
};

export default WorkFlowFooterAction;
