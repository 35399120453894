import { RouterProvider, json } from "react-router-dom";

import { AuthorizedRouter, MainRouter } from "./constants/routes";
import {
  client_credentials,
  get_Public_authentication_profiles,
  get_service_agreements,
} from "./services/api/Auth/scripts";
import "./App.css";
import { useEffect, useState } from "react";
import { ConfigProvider } from "antd";
import {
  about_signingHub,
  get_languages,
  get_system_settings,
} from "./services/api/General/scripts";
import Loading from "./components/Loading";
import {
  get_general_profile_information,
  get_general_profile_picture,
} from "./services/api/Settings/scripts";
import { get_enterprise_users } from "./services/api/Enterprise/scripts";

function App() {
  const timeOut = async () => {
    const res = await get_system_settings();
  };
  useEffect(() => {
    const intervalId = setInterval(timeOut, 60000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);
  const password_token = sessionStorage.getItem("password_token");

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch_essential_data();
  }, [password_token]);

  const fetch_essential_data = async () => {
    try {
      await fetch_client_credintial();
      await Promise.all([
        get_public_profiles(),
        get_languages_data(),
        get_terms_services(),
        get_about(),
        get_profile_photo(),
        get_profile(),
        get_system_global_settings(),
        get_terms_and_privacy(),
        get_users(),
      ]);
      setLoading(false);
    } catch (err) {
      // console.log(err);
      setLoading(false);
    }
  };

  const get_system_global_settings = async () => {
    try {
      const res = await get_system_settings();
      sessionStorage.setItem("system_settings", JSON.stringify(res));
    } catch (err) {}
  };
  const get_terms_and_privacy = async () => {
    try {
      const res = await get_service_agreements();
      sessionStorage.setItem("terms", JSON.stringify(res.terms_of_service));
      sessionStorage.setItem("privacy", JSON.stringify(res.privacy_policy));
    } catch (err) {}
  };
  const fetch_client_credintial = async () => {
    const res = await client_credentials();
    sessionStorage.setItem(
      "client_credentials_token",
      JSON.stringify(res.access_token)
    );
    sessionStorage.setItem(
      "client_credentials_token_expires",
      JSON.stringify(res.expires_in)
    );
  };
  const get_public_profiles = async () => {
    try {
      const res = await get_Public_authentication_profiles();
      sessionStorage.setItem("authentication_profiles", JSON.stringify(res));
    } catch (err) {
      sessionStorage.setItem("authentication_profiles", JSON.stringify([]));
      // console.log(err);
    }
  };
  const get_languages_data = async () => {
    try {
      const res = await get_languages();
      sessionStorage.setItem("languages", JSON.stringify(res));
    } catch (err) {
      sessionStorage.setItem("languages", JSON.stringify([]));
      // console.log(err);
    }
  };
  const get_terms_services = async () => {
    try {
      const res = await get_service_agreements();
      sessionStorage.setItem("terms", JSON.stringify(res));
    } catch (err) {
      sessionStorage.setItem("terms", JSON.stringify([]));
      // console.log(err);
    }
  };
  const get_about = async () => {
    try {
      const res = await about_signingHub();
      sessionStorage.setItem("about", JSON.stringify(res));
    } catch (err) {
      sessionStorage.setItem("about", JSON.stringify([]));
      // console.log(err);
    }
  };
  const get_profile_photo = async () => {
    if (password_token) {
      try {
        const res = await get_general_profile_picture();
        // console.log(res);
        sessionStorage.setItem("photo", JSON.stringify(res.photo));
      } catch (err) {
        sessionStorage.setItem("photo", JSON.stringify(null));
        // console.log(err);
      }
    }
  };
  const get_profile = async () => {
    if (password_token) {
      try {
        const res = await get_general_profile_information();
        // console.log(res);
        sessionStorage.setItem("profile", JSON.stringify(res));
      } catch (err) {
        sessionStorage.setItem("profile", JSON.stringify(null));
        // console.log(err);
      }
    }
  };
  const get_users = async () => {
    if (password_token) {
      try {
        const res = await get_enterprise_users(1, 1000, "");
        sessionStorage.setItem("users", JSON.stringify(res));
      } catch (err) {
        sessionStorage.setItem("profile", JSON.stringify([]));
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loading spinning={loading} />
      ) : (
        <RouterProvider router={MainRouter} />
      )}
    </>

    // <RouterProvider router={MainRouter} />
  );
}

export default App;
