import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { PathConstants } from "../constants/paths";
import MainLayout from "./MainLayout";
import { UserAccessContext } from "../context/UserAccessContext";
import { useContext, useEffect } from "react";
import { get_user_role } from "../services/api/Account/scripts";
import { notification } from "antd";

export default function ProtectedRoute() {
  const password_token = sessionStorage.getItem("password_token");
  const { dispatch } = useContext(UserAccessContext);
  const location = useLocation();

  useEffect(() => {
    const fetchUserAccess = async () => {
      try {
        const response = await get_user_role();
        if (!response) {
          throw new Error("Error while getting user information");
        }
        dispatch({
          type: "UPDATE",
          payload: response,
        });
      } catch (err) {
        notification.error({
          message: err?.message || "Error while getting user information",
        });
      }
    };
    if (!!password_token) {
      fetchUserAccess();
    }
  }, [password_token, dispatch]);

  return !password_token ? (
    <Navigate to={PathConstants.Login} replace state={{ from: location }} />
  ) : (
    <MainLayout />
  );
}
