import "./ErrorPage.css";
import ErrorImg from "../../assets/images/ErrorPage/ErrorImg.png";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

export default function ErrorPage({ resetErrorBoundary, error, errorInfo }) {
  const { t } = useTranslation("translation", {
    keyPrefix: "error",
  });

  return (
    <div className="error-page">
      {resetErrorBoundary && (
        <div className="error-container">
          <img className="errorImg" src={ErrorImg} alt="loginImage" />
          <p className="errorMsg">{t("something_wrong")}</p>

          <Button
            className="retry-button"
            type="primary"
            size="large"
            onClick={resetErrorBoundary}
          >
            {t("reload_page")}
          </Button>
        </div>
      )}
    </div>
  );
}
