import { Select } from "antd";
import { Footer } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PathConstants } from "../constants/paths";
import { useMediaQuery } from "react-responsive";
import i18next from "i18next";

const SiteFooter = ({ login = false }) => {
  const isMobile = useMediaQuery({ maxWidth: 850 });
  const { t } = useTranslation("translation", { keyPrefix: "languages" });
  const [defaultLanguage, setDefaultLanguage] = useState(i18next.language);
  const [languages, setLanguages] = useState([]);
  const [rights, setRights] = useState(
    " Copyright © 2023 Signing hub. All rights reserved"
  );

  useEffect(() => {
    const langs = JSON.parse(sessionStorage.getItem("languages"));
    const about = JSON.parse(sessionStorage.getItem("about"));

    console.log("langs", langs);
    setRights(() => `Copyright © ${about?.copyright}`);
    setLanguages(() =>
      langs
        ? Object.keys(langs?.supported_languages).map((key) => {
            return {
              key: key,
              label: langs?.supported_languages[key],
              value: key,
            };
          })
        : []
    );
  }, []);

  const changeLang = (value) => {
    i18next.changeLanguage(value).then(() => {
      // Update the language state to trigger a rerender
      setDefaultLanguage(value);
    });
  };
  return (
    <>
      {!isMobile || login ? (
        <Footer
          className={`w-[100%] flex items-center  ${
            login ? "p-0" : "px-[5%]"
          } bg-[#fff] mt-auto h-[50px]  border border-t-[2px] justify-between`}
          id="footer"
        >
          <div
            className={`${
              login ? "w-full flex items-center justify-center gap-2" : ""
            }`}
          >
            <a
              className={`${
                login && isMobile
                  ? "text-zinc-900 text-xs font-normal font-['Poppins']"
                  : "mr-[10px] border-r-[2px] pr-[5px] border-[#C3C3C3] text-[#1C1E25] text-[12px] font-[400]"
              }`}
              href="/terms"
            >
              {t("terms")}
            </a>
            <a
              className={`${
                login && isMobile
                  ? "text-zinc-900 text-xs font-normal font-['Poppins']"
                  : "mr-[10px] border-r-[2px] pr-[5px] border-[#C3C3C3] text-[#1C1E25] text-[12px] font-[400]"
              }`}
              href="/Privacy"
            >
              {t("privacy")}
            </a>
            <Select
              defaultValue={defaultLanguage}
              style={{ zIndex: "0" }}
              onChange={(value) => {
                changeLang(value);
              }}
              options={languages}
            />
          </div>
          {login || isMobile ? "" : <div className=" rights ">{rights}</div>}
        </Footer>
      ) : (
        ""
      )}
    </>
  );
};
export default SiteFooter;
