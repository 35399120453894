import { BASE_URL } from "../../global";

//https://api.signinghub.com/v4/account/electronic-seals
export const get_user_electronic_seal_profiles = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  try {
    const response = await fetch(`${BASE_URL}/v4/account/electronic-seals`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const body = await response.json();
    return body;
  } catch (error) {
    throw error;
  }
};

//https://api.signinghub.com/v4/account/invitations
export const get_account_invitations = async (user_email) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    user_email,
  };
  try {
    const res = await fetch(`${BASE_URL}/v4/account/invitations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });
    if (!res.ok) {
      throw new Error(res.status);
    }

    return res;
  } catch (error) {
    throw error;
  }
};

//hhttps://api.signinghub.com/v4/account/invitations
export const accept_account_invitation = async (enterprise_name) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    enterprise_name,
  };
  try {
    const res = await fetch(`${BASE_URL}/v4/account/invitations`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });
    if (!res.ok) {
      throw new Error(res.status);
    }
    return res;
  } catch (error) {
    throw error;
  }
};

//hhttps://api.signinghub.com/v4/account/invitations
export const reject_account_invitations = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  try {
    const res = await fetch(`${BASE_URL}/v4/account/invitations`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(res.status);
    }
    return res;
  } catch (error) {
    throw error;
  }
};
/**
 * name: get_notifications
 * args: none
 * return: promise response
 * response contains document statistics
 * objective:  get  document statistics to show in home page
 */
export const register_user = async (user_name, user_email, enterprise_name) => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const postData = {
    user_name: user_name,
    user_email: user_email,
    job_title: "",
    company_name: "",
    mobile_number: "",
    country: "",
    time_zone: "",
    language: "",
    invitation: {
      enterprise_name,
    },
    service_agreements: true,
    user_csp_id: "",
    user_ra_id: "",
    user_csc_id: "",
    marketing_emails: true,
  };
  try {
    const res = await fetch(`${BASE_URL}/v4/account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });
    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

//https://api.signinghub.com/v4/account

export const get_account = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  try {
    const res = await fetch(`${BASE_URL}/v4/account`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

//https://api.signinghub.com/v4/account/activation/resend
export const resend_activation_email = async (user_email) => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const postData = {
    user_email: user_email,
  };
  try {
    const res = await fetch(`${BASE_URL}/v4/account/activation/resend`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });
    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

//https://api.signinghub.com/v4/account/remove/feedback
export const add_feedback_after_delete_account = async (feedBack, q) => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const postData = {
    feedBack: feedBack,
    q: q,
  };
  try {
    const res = await fetch(`${BASE_URL}/v4/account/remove/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });
    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};
//https://api.signinghub.com/v4/account/password/reset
export const reset_password = async (
  security_answer,
  user_new_password,
  user_email
) => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const postData = {
    security_answer: security_answer,
    user_new_password: user_new_password,
    user_email: user_email,
  };
  try {
    const res = await fetch(`${BASE_URL}/v4/account/password/reset`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });
    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};
//https://api.signinghub.com/v4/account/password/reset
export const send_fprget_password_request = async (user_email) => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const postData = {
    user_email: user_email,
  };
  try {
    const res = await fetch(`${BASE_URL}/v4/account/password/reset`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });
    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};
//https://api.signinghub.com/v4/account/password/new
export const set_new_password = async (
  security_question,
  security_answer,
  password,
  q
) => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const postData = {
    security_question: security_question,
    security_answer: security_answer,
    password,
    q,
  };
  try {
    const res = await fetch(`${BASE_URL}/v4/account/password/new`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

//https://api.signinghub.com/v4/account/statistics/usage
export const get_account_usage = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  try {
    const res = await fetch(`${BASE_URL}/v4/account/statistics/usage`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 * name: get_documents_statistics
 * args: none
 * return: promise response
 * response contains document statistics
 * objective:  get  document statistics to show in home page
 */
export const get_documents_statistics = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  try {
    const res = await fetch(`${BASE_URL}/v4/account/statistics/documents`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

//https://api.signinghub.com/v4/account/password_policy
export const get_password_policy = async (user_email) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    user_email,
  };
  try {
    const res = await fetch(`${BASE_URL}/v4/account/password_policy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

//https://api.signinghub.com/v4/account/role
export const get_user_role = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  try {
    const res = await fetch(`${BASE_URL}/v4/account/role`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 * name: get_user_activity_details
 * args:  , logId
 * return: promise response
 * response contains document statistics
 * objective:  get  user activites https://api.signinghub.com/v4/account/log/{logId}/details
 */
export const get_user_activity_details = async (logId) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  try {
    const res = await fetch(`${BASE_URL}/v4/account/log/${logId}/details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 * name: get_user_activity
 * args: recordsPerPage ,  pageNo
 * return: promise response
 * response contains document statistics
 * objective:  get  user activites
 */
export const get_user_activity = async (pageNo, recordsPerPage) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  try {
    const res = await fetch(
      `${BASE_URL}/v4/account/log/${pageNo}/${recordsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

//https://api.signinghub.com/v4/account/identity
export const add_identify_for_user = async (user_email, name, key, value) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    user_email,
    name,
    key,
    value,
  };
  try {
    const res = await fetch(`${BASE_URL}/v4/account/identity`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 * name: get_notifications
 * args: none
 * return: promise response
 * response contains document statistics
 * objective:  get  document statistics to show in home page
 */
export const get_notifications = async (recordPerPage, pageNo) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  try {
    const res = await fetch(
      `${BASE_URL}/v4/account/notifications/${recordPerPage}/${pageNo}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 * name: get_notifications
 * args: none
 * return: promise response
 * response
 * objective:  mark account notifications as read
 */
export const mark_notifications_as_read = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {};
  try {
    const res = await fetch(`${BASE_URL}/v4/account/notifications`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        "x-mark-as-read": true,
      },
      body: JSON.stringify(postData),
    });

    if (!res.ok) {
      throw new Error(res.status);
    }

    return res;
  } catch (error) {
    throw error;
  }
};

//https://api.signinghub.com/v4/users/notifications/devices
export const device_register_for_push_notifiations = async (
  device_token,
  os_type
) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    device_token,
    os_type,
  };
  try {
    const res = await fetch(`${BASE_URL}/v4/users/notifications/devices`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

//https://api.signinghub.com/v4/account/two-factor-authentication/configure/{packageId}
export const configure_two_factor_auth = async (packageId) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {};
  try {
    const res = await fetch(
      `${BASE_URL}/v4/account/two-factor-authentication/configure/${packageId}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(postData),
      }
    );

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};
