import React from "react";
import { usePopup } from "../context/PopupContext";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
const Popup = () => {
  const { popup, closePopup } = usePopup();
  return (
    popup && (
      <div className="z-50 fixed top-0 left-0 w-[100%] h-[100%] flex justify-center items-center bg-gray-500/50  ">
        <div
          className={`relative bg-[#fff] py-[2em] rounded-[2em] shadow-xl  max-h-[90vh] h-fit flex flex-col gap-8	`}
          style={{ width: `${Number(popup.width)}em` }}
        >
          <div className="flex justify-between px-[1.25em]">
            <p className="text-[1.5em] font-[500] text-black m-auto capitalize">
              {popup.title}
            </p>
            <CloseCircleOutlined
              className="text-[1.5em] font-[500] text-black"
              onClick={closePopup}
            />
          </div>
          {/* <div className=" ">
          </div> */}
          {popup.content}
        </div>
      </div>
    )
  );
};

export default Popup;