import { BASE_URL } from "../../global";

/**
 *  name: oTP_verification
 *  objective: use this service API to verify an OTP, generated using OTP Login Authentication.
 *  args: none
 *  return: promise response
 */
const oTP_verification = async (otp) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/authentication/otp/verify`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "x-otp": otp,
    },
  });

  return res;
};

/***
 * name: get_profile_picture_of_recipient
 * objective: get the profile picture of a recipient that has signed the document.
 * args: encryptKey
 * return: promise response
 */
const get_profile_picture_of_recipient = async (encryptKey) => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/profile/general/photo/${encryptKey}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name: about_signingHub
 * objective: this service API to get the SigningHub version and build information.
 * args: none
 * return: promise response
 */
const about_signingHub = async () => {
  const res = (
    await fetch(`${BASE_URL}/v4/about`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name: get_system_settings
 * objective: API to get the system settings of a logged in enterprise user
 * args: none
 * return: promise response
 */
const get_system_settings = async () => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/system/configurations/global`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name: get_signingHub_admin_branding
 * objective: Retrieve the default branding of SigningHub Application
 * args: none
 * return: promise response
 */
const get_signingHub_admin_branding = async () => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/system/configurations/branding`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name: get_SH_admin_branding_logo
 * objective: get the application logo configured in the application's admin console branding.
 * args: none
 * return: promise response
 */

const get_SH_admin_branding_logo = async () => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/system/configurations/branding/logo`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name: get_SH_admin_branding_favicon
 * objective: get the application favicon configured in the application's admin console branding.
 * args: none
 * return: promise response
 */
const get_SH_admin_branding_favicon = async () => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/system/configurations/branding/favicon`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

/***
 * name: get_languages
 * objective: Business applications can use this service API to get the supported languages
 * args: none
 * return: promise response {default_language: string , allow_changing_language: bool , supported_languages:
 * {
 *   kay: name , key: name .....
 * }
 *
 *
 * ]}
 */
const get_languages = async () => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/system/configurations/languages`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

//https://api.signinghub.com/v4/system/configurations/countries
const get_countries = async () => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/system/configurations/countries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};
//https://api.signinghub.com/v4/system/configurations/timezones
const get_timezones = async () => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/system/configurations/timezones`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};
const get_time_trail = async () => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/system/configurations/global`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();


};
export {
  oTP_verification,
  get_profile_picture_of_recipient,
  about_signingHub,
  get_system_settings,
  get_signingHub_admin_branding,
  get_SH_admin_branding_logo,
  get_SH_admin_branding_favicon,
  get_languages,
  get_timezones,
  get_countries,
  get_time_trail
};


