import { Button, ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import App from "../App";
import { useEffect } from "react";
import { PopupProvider } from "../context/PopupContext";
import { UserAccessContextProvider } from "../context/UserAccessContext";

const AntdWrapper = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    // Update language direction when i18n language changes
    document.dir = i18n.language === "ar-AE" ? "rtl" : "ltr";
    // console.log(i18n.language)
  }, [i18n.language]);

  return (
    <ConfigProvider
      direction={i18n.language === "ar-AE" ? "rtl" : "ltr"}
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#2cc400",
          colorPrimaryActive: "#beedb0",
          colorPrimaryHover: "#dff6d9",
        },
        components: {
          Checkbox: {
            colorPrimary: "#1c1e25",
            colorPrimaryActive: "#16181e",
            colorPrimaryHover: "#191b21",
          },
          Tabs: {
            colorPrimary: "#1c1e25",
            colorPrimaryActive: "#16181e",
            colorPrimaryHover: "#191b21",
          },
        },
      }}
    >
      <UserAccessContextProvider>
        <PopupProvider>
          <>
            <App />
          </>
        </PopupProvider>
      </UserAccessContextProvider>
    </ConfigProvider>
  );
};

export default AntdWrapper;
