import loading from "../assets/images/logo.webp";

const Loading = ({ spinning, fullscreen = true }) => {
  return (
    spinning && (
      <div className="  w-[100vw] h-[100vh] bg-black bg-opacity-40 fixed top-0 left-0 z-50">
        <img
          src={loading}
          alt="loading"
          style={{
            position: "fixed",
            width: "200px",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    )
  );
};
export default Loading;
