import { lazy } from "react";

const Home = lazy(() => import("../pages/home/Home"));

const Documents = lazy(() => import("../pages/documents/Documents"));
const ContactUs = lazy(() => import("../pages/contactUs/ContactUs"));
const Library = lazy(() => import("../pages/library/Library"));
const Templates = lazy(() => import("../pages/templates/Templates"));

const Login = lazy(() => import("../pages/auth/Login"));
const SignUp = lazy(() => import("../pages/auth/SignUp"));

const Terms = lazy(() => import("../pages/terms/Terms"));
const Privacy = lazy(() => import("../pages/Privacy/Privacy"));

const Recipients = lazy(() =>
  import("../pages/documents/recipients/Recipients")
);
const Prepare = lazy(() => import("../pages/documents/prepare/PrepareDocView"));

const Send = lazy(() => import("../pages/documents/send/Send"));

const Sign = lazy(() => import("../pages/documents/prepare/PrepareDocView"));
const ViewDoc = lazy(() => import("../pages/documents/prepare/PrepareDocView"));

// Settings pages
const ProfileSettings = lazy(() => import("../pages/settings/profile"));
const SignatureSettings = lazy(() => import("../pages/settings/signature"));
const NotificationSettings = lazy(() =>
  import("../pages/settings/notificationSettings")
);
const CloudStorageSettings = lazy(() =>
  import("../pages/settings/cloudStorage")
);
const LegalNoticeSettings = lazy(() => import("../pages/settings/legalNotice"));
const DelegateSettengs = lazy(() =>
  import("../pages/settings/delegateSettings")
);
const DeleteAccountSettengs = lazy(() =>
  import("../pages/settings/deleteAccount")
);
const Contacts = lazy(() => import("../pages/contact/Contact"));
const Groups = lazy(() => import("../pages/groups/Groups"));
const Activity = lazy(() => import("../pages/Activity/Activity"));

///Enterprise import section
const EnterpriseUsers = lazy(() =>
  import("../pages/settings/enterprise/users")
);
const EnterpriseIntegrations = lazy(() =>
  import("../pages/settings/enterprise/integration")
);
const EnterpriseBranding = lazy(() =>
  import("../pages/settings/enterprise/branding")
);
const EnterpriseReports = lazy(() =>
  import("../pages/settings/enterprise/reports")
);
const AdvancedEnterpriseSettings = lazy(() =>
  import("../pages/settings/enterprise/advanced")
);

export const PagesConstants = {
  Home,
  Documents,
  ContactUs,
  Library,
  Templates,
  Login,
  SignUp,
  Recipients,
  Prepare,
  Send,
  Sign,
  Terms,
  Privacy,
  ProfileSettings,
  SignatureSettings,
  NotificationSettings,
  CloudStorageSettings,
  LegalNoticeSettings,
  DelegateSettengs,
  DeleteAccountSettengs,
  ViewDoc,
  Contacts,
  Groups,
  Activity,
  EnterpriseUsers,
  EnterpriseIntegrations,
  EnterpriseBranding,
  EnterpriseReports,
  AdvancedEnterpriseSettings,
};
