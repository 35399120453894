import { useEffect } from "react";
import { BASE_URL } from "../../global";

export const get_enterprise_users = async (
  pageNo,
  recordsPerPage,
  searchText
) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = (
    await fetch(`${BASE_URL}/v4/enterprise/users/${pageNo}/${recordsPerPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...(searchText && { "x-search-text": searchText }),
      },
    })
  ).json();

  const body = await res;
  return body;
};

export const register_enterprise_user = async (postData) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(`${BASE_URL}/v4/enterprise/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

export const bulk_register_enterprise_user = async (postData) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(`${BASE_URL}/v4/enterprise/users/bulk`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

export const update_enterprise_user = async (id, postData) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(`${BASE_URL}/v4/enterprise/users/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

export const delete_enterprise_user = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(`${BASE_URL}/v4/enterprise/users/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

export const add_enterprise_group = async (Name, Description, Members) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    Name: Name,
    Description: Description,
    Members: Members,
  };
  const res = await fetch(`${BASE_URL}/v4/enterprise/groups`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

export const update_enterprise_group = async (
  id,
  Name,
  Description,
  Members
) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    Name: Name,
    Description: Description,
    Members: Members,
  };
  const res = await fetch(`${BASE_URL}/v4/enterprise/groups/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });
  return res;
};

export const update_enterprise_profile = async (postData) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(`${BASE_URL}/v4/enterprise/profile`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

export const get_enterprise_library_folders = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = (
    await fetch(`${BASE_URL}/v4/enterprise/library/folders`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();
  return res;
};

export const get_enterprise_roles = async (
  pageNo,
  recordsPerPage,
  searchText
) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = (
    await fetch(`${BASE_URL}/v4/enterprise/roles/${pageNo}/${recordsPerPage}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        ...(searchText && { "x-search-text": searchText }),
      },
    })
  ).json();

  const body = await res;
  return body;
};

export const add_enterprise_roles = async (postData) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = (
    await fetch(`${BASE_URL}/v4/enterprise/roles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    })
  ).json();

  return res;
};

export const delete_enterprise_roles = async (roleId) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(`${BASE_URL}/v4/enterprise/roles/${roleId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

export const get_enterprise_invitations = async (pageNo, recordsPerPage) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = (
    await fetch(
      `${BASE_URL}/v4/enterprise/invitations/${pageNo}/${recordsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();

  return res;
};

export const invite_enterprise_user = async (postData) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = (
    await fetch(`${BASE_URL}/v4/enterprise/invitations`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    })
  ).json();

  return res;
};

export const bulk_invite_enterprise_user = async (postData) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = (
    await fetch(`${BASE_URL}/v4/enterprise/invitations/bulk`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    })
  ).json();

  return res;
};

export const delete_enterprise_user_invitation = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(`${BASE_URL}/v4/enterprise/invitations/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

export const resend_enterprise_user_invitation = async (id) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(
    `${BASE_URL}/v4/enterprise/invitations/${id}/resend`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res;
};

//https://api.signinghub.com/v4/enterprise/integrations/{pageNo}/{recordPerPage}
export const get_enterprise_integrations = async (pageNo, recordPerPage) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = (
    await fetch(
      `${BASE_URL}/v4/enterprise/integrations/${pageNo}/${recordPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();
  return res;
};

//https://api.signinghub.com/v4/enterprise/integrations
// allowed_roles_scope []
export const add_enterprise_integration = async (
  client_id,
  callback_url,
  hide_recipients_documents,
  role_external_users,
  allowed_roles_scope,
  allowed_domains,
  collapse_panels,
  redirect_callback_url
) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    client_id,
    callback_url,
    hide_recipients_documents,
    role_external_users,
    allowed_roles_scope,
    allowed_domains,
    collapse_panels,
    redirect_callback_url,
  };
  console.log(postData);
  const res = await fetch(`${BASE_URL}/v4/enterprise/integrations`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};
//https://api.signinghub.com/v4/enterprise/branding

export const get_signingHub_enterprise_branding = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/enterprise/branding`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

//https://api.signinghub.com/v4/enterprise/branding
export const update_enterprise_branding = async (
  logo,
  favicon,
  signature_logo,
  email_logo,
  level_1,
  level_2,
  level_3,
  level_4,
  level_5,
  level_6,
  level_7,
  level_8,
  level_9,
  level_10,
  level_11,
  level_12,
  level_13
) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    logo,
    favicon,
    signature_logo,
    email_logo,
    colors: {
      level_1,
      level_2,
      level_3,
      level_4,
      level_5,
      level_6,
      level_7,
      level_8,
      level_9,
      level_10,
      level_11,
      level_12,
      level_13,
    },
  };
  console.log(postData);
  const res = await fetch(`${BASE_URL}/v4/enterprise/branding`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/enterprise/notifications

export const get_enterprise_setting_notifications = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/enterprise/notifications`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

//https://api.signinghub.com/v4/settings/notifications
export const get_personal_setting_notifications = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/notifications`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
  ).json();
  const body = await res;
  return body;
};
//https://api.signinghub.com/v4/enterprise/notifications

export const update_enterprise_notifications_settings = async (
  templates_limit_reached_screen,
  templates_limit_reached_email,
  signature_limit_reached_screen,
  signature_limit_reached_email,
  workflow_limit_reached_screen,
  workflow_limit_reached_email,
  simple_electronic_signature_limit_reached_screen,
  simple_electronic_signature_limit_reached_email,
  user_limit_reached_screen,
  user_limit_reached_email,
  serviceplan_reset,
  serviceplan_change,
  online_payment,
  forgot_password,
  password_changed_screen,
  password_changed_email,
  login_failed,
  document_processed_by_others_screen,
  document_processed_by_others_email,
  document_processed_by_others_push,
  document_completed_screen,
  document_completed_email,
  document_completed_push,
  daily_summary,
  document_subsequent_changes_screen,
  document_subsequent_changes_email,
  document_subsequent_changes_push,
  process_document,
  recall_document_recipients,
  recall_document_screen,
  recall_document_email,
  share_document_screen,
  share_document_email,
  share_document_push
) => {
  const postData = {
    notification_setting: {
      templates_limit_reached: {
        on_screen: {
          enabled: templates_limit_reached_screen,
        },
        email: {
          enabled: templates_limit_reached_email,
        },
      },
      signature_limit_reached: {
        on_screen: {
          enabled: signature_limit_reached_screen,
        },
        email: {
          enabled: signature_limit_reached_email,
        },
      },
      workflow_limit_reached: {
        on_screen: {
          enabled: workflow_limit_reached_screen,
        },
        email: {
          enabled: workflow_limit_reached_email,
        },
      },
      simple_electronic_signature_limit_reached: {
        on_screen: {
          enabled: simple_electronic_signature_limit_reached_screen,
        },
        email: {
          enabled: simple_electronic_signature_limit_reached_email,
        },
      },
      user_limit_reached: {
        on_screen: {
          enabled: user_limit_reached_screen,
        },
        email: {
          enabled: user_limit_reached_email,
        },
      },
      serviceplan_reset: {
        on_screen: {
          enabled: serviceplan_reset,
        },
      },
      serviceplan_change: {
        on_screen: {
          enabled: serviceplan_change,
        },
      },
      online_payment: {
        on_screen: {
          enabled: online_payment,
        },
      },
      forgot_password: {
        on_screen: {
          enabled: forgot_password,
        },
      },
      password_changed: {
        on_screen: {
          enabled: password_changed_screen,
        },
        email: {
          enabled: password_changed_email,
        },
      },
      login_failed: {
        on_screen: {
          enabled: login_failed,
        },
      },
      document_processed_by_others: {
        on_screen: {
          enabled: document_processed_by_others_screen,
        },
        email: {
          enabled: document_processed_by_others_email,
        },
        push: {
          enabled: document_processed_by_others_push,
        },
      },
      document_completed: {
        on_screen: {
          enabled: document_completed_screen,
        },
        email: {
          enabled: document_completed_email,
        },
        push: {
          enabled: document_completed_push,
        },
      },
      daily_summary: {
        email: {
          enabled: daily_summary,
        },
      },
      document_subsequent_changes: {
        on_screen: {
          enabled: document_subsequent_changes_screen,
        },
        email: {
          enabled: document_subsequent_changes_email,
        },
        push: {
          enabled: document_subsequent_changes_push,
        },
      },
      process_document: {
        email: {
          enabled: process_document,
        },
      },
      recall_document: {
        recipients: recall_document_recipients,
        on_screen: {
          enabled: recall_document_screen,
        },
        email: {
          enabled: recall_document_email,
        },
      },
      share_document: {
        on_screen: {
          enabled: share_document_screen,
        },
        email: {
          enabled: share_document_email,
        },
        push: {
          enabled: share_document_push,
        },
      },
    },
  };

  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/enterprise/notifications`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/settings/notifications

export const update_personal_notifications_settings = async (values) => {
  const postData = {
    notification_setting: {
      templates_limit_reached: {
        on_screen: {
          enabled: values?.templates_limit_reached_on_screen,
        },
        email: {
          enabled: values?.templates_limit_reached_email,
        },
      },
      signature_limit_reached: {
        on_screen: {
          enabled: values?.signature_limit_reached_on_screen,
        },
        email: {
          enabled: values?.signature_limit_reached_email,
        },
      },
      workflow_limit_reached: {
        on_screen: {
          enabled: values?.workflow_limit_reached_on_screen,
        },
        email: {
          enabled: values?.workflow_limit_reached_email,
        },
      },
      simple_electronic_signature_limit_reached: {
        on_screen: {
          enabled: values?.simple_electronic_signature_limit_reached_on_screen,
        },
        email: {
          enabled: values?.simple_electronic_signature_limit_reached_email,
        },
      },
      user_limit_reached: {
        on_screen: {
          enabled: values?.user_limit_reached_on_screen,
        },
        email: {
          enabled: values?.user_limit_reached_email,
        },
      },
      serviceplan_reset: {
        on_screen: {
          enabled: values?.serviceplan_reset_on_screen,
        },
      },
      serviceplan_change: {
        on_screen: {
          enabled: values?.serviceplan_change_on_screen,
        },
      },
      online_payment: {
        on_screen: {
          enabled: values?.online_payment_on_screen,
        },
      },
      forgot_password: {
        on_screen: {
          enabled: values?.forgot_password_on_screen,
        },
      },
      password_changed: {
        on_screen: {
          enabled: values?.password_changed_on_screen,
        },
        email: {
          enabled: values?.password_changed_email,
        },
      },
      login_failed: {
        on_screen: {
          enabled: values?.login_failed_on_screen,
        },
      },
      document_processed_by_others: {
        on_screen: {
          enabled: values?.document_processed_by_others_on_screen,
        },
        email: {
          enabled: values?.document_processed_by_others_email,
        },
        push: {
          enabled: values?.document_processed_by_others_push,
        },
      },
      document_completed: {
        on_screen: {
          enabled: values?.document_completed_on_screen,
        },
        email: {
          enabled: values?.document_completed_email,
        },
        push: {
          enabled: values?.document_completed_push,
        },
      },
      daily_summary: {
        email: {
          enabled: values?.daily_summary_email,
        },
      },
      document_subsequent_changes: {
        on_screen: {
          enabled: values?.document_subsequent_changes_on_screen,
        },
        email: {
          enabled: values?.document_subsequent_changes_email,
        },
        push: {
          enabled: values?.document_subsequent_changes_push,
        },
      },
      process_document: {
        email: {
          enabled: values?.process_document,
        },
      },
      recall_document: {
        recipients: values?.recipients,
        on_screen: {
          enabled: values?.recall_document_on_screen,
        },
        email: {
          enabled: values?.recall_document_email,
        },
      },
      share_document: {
        on_screen: {
          enabled: values?.share_document_on_screen,
        },
        email: {
          enabled: values?.share_document_email,
        },
        push: {
          enabled: values?.share_document_push,
        },
      },
    },
  };

  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/settings/notifications`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/enterprise/notifications/emails

export const get_enterprise_email_settings = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/enterprise/notifications/emails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

//https://api.signinghub.com/v4/settings/notifications/emails

export const get_personal_email_settings = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/settings/notifications/emails`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};

//https://api.signinghub.com/v4/enterprise/notifications/email/reset
export const reset_enterprise_email_settings = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {};
  const res = await fetch(
    `${BASE_URL}/v4/enterprise/notifications/email/reset`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    }
  );

  return res;
};

//https://api.signinghub.com/v4/settings/notifications/email/reset
export const reset_personal_email_settings = async () => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {};
  const res = await fetch(`${BASE_URL}/v4/settings/notifications/email/reset`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });
  return res;
};

//https://api.signinghub.com/v4/enterprise/notifications/emails
export const update_enterprise_email_settings = async (type, subject, body) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    type,
    subject,
    body,
  };

  const res = await fetch(`${BASE_URL}/v4/enterprise/notifications/emails`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

//https://api.signinghub.com/v4/settings/notifications/emails
export const update_personal_email_settings = async (type, subject, body) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    type,
    subject,
    body,
  };

  const res = await fetch(`${BASE_URL}/v4/settings/notifications/emails`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};


//https://api.signinghub.com/v4/enterprise/integrations/{integrationId}/external-users/{pageNo}/{recordPerPage}

export const get_enterprise_integration_external_users = async (
  integrationId,
  pageNo,
  recordPerPage
) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(
      `${BASE_URL}/v4/enterprise/integrations/${integrationId}/external-users/${pageNo}/${recordPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();

  const body = await res;
  return body;
};

//https://api.signinghub.com/v4/enterprise/integrations/{integrationId}/external-users/{externalUserId}
export const delete_enterprise_integration_external_user = async (
  integrationId,
  externalUserId
) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(
    `${BASE_URL}/v4/enterprise/integrations/${integrationId}/external-users/${externalUserId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return res;
};
//https://api.signinghub.com/v4/enterprise/integrations/{integrationId}/external-users
export const add_enterprise_integration_external_user = async (
  integrationId,
  user_email,
  user_role
) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    user_email,
    user_role,
  };
  console.log(postData);
  const res = await fetch(
    `${BASE_URL}/v4/enterprise/integrations/${integrationId}/external-users`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    }
  );

  return res;
};

//https://api.signinghub.com/v4/enterprise/integrations/{integrationId}/external-users/{externalUserId}
export const update_enterprise_integration_external_user = async (
  integrationId,
  externalUserId,
  user_email,
  user_role
) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {
    user_email,
    user_role,
  };
  const res = await fetch(
    `${BASE_URL}/v4/enterprise/integrations/${integrationId}/external-users/${externalUserId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    }
  );

  return res;
};
//https://api.signinghub.com/v4/enterprise/packages/{packageId}/details
export const get_package_details = async (packageId) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(`${BASE_URL}/v4/enterprise/packages/${packageId}/details`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
  ).json();

  const body = await res;
  return body;
};
//https://api.signinghub.com/v4/enterprise/packages/{packageId}/log/{pageNo}/{recordPerPage}
export const get_package_history = async (packageId, pageNo, recordPerPage) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(
      `${BASE_URL}/v4/enterprise/packages/${packageId}/log/${pageNo}/${recordPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();

  const body = await res;
  return body;
};
//https://api.signinghub.com/v4/enterprise/packages/{packageId}

export const delete_enterprise_package = async (packageId) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));

  const res = await fetch(`${BASE_URL}/v4/enterprise/packages/${packageId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};
//https://api.signinghub.com/v4/enterprise/packages/{packageId}/complete
export const terminate_enterprise_package = async (packageId) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const postData = {};
  const res = await fetch(
    `${BASE_URL}/v4/enterprise/packages/${packageId}/complete`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    }
  );

  return res;
};
//https://api.signinghub.com/v4/enterprise/packages/{document_status}/{page_no}/{records_per_page}

export const get_all_enterprise_packages = async (
  document_status,
  pageNo,
  recordPerPage
) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(
      `${BASE_URL}/v4/enterprise/packages/${document_status}/${pageNo}/${recordPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();

  const body = await res;
  return body;
};
//https://api.signinghub.com/v4/packages/{packageId}/log/{logId}/details

export const get_package_history_log_detail = async (packageId, logId) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(
      `${BASE_URL}/v4/enterprise/packages/${packageId}/log/${logId}/details`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();

  const body = await res;
  return body;
};

//https://api.signinghub.com/v4/enterprise/packages/{packageId}/workflow/users
export const get_enterprise_workflow_users = async (packageId) => {
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = (
    await fetch(
      `${BASE_URL}/v4/enterprise/packages/${packageId}/workflow/users`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
  ).json();

  const body = await res;
  return body;
};
