import {
  UserOutlined,
  UsergroupAddOutlined,
  PieChartOutlined,
  SafetyCertificateOutlined,
  LinkOutlined,
  FormatPainterOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { EnterprisePaths } from "./paths";

// export const ENTERPRISE_SETTINGS_ROUTES = [
//   {
//     path: EnterprisePaths.Profile,
//     label: "Profile",
//     icon: <UserOutlined />,
//   },
//   {
//     path: EnterprisePaths.Users,
//     label: "Users",
//     icon: <UsergroupAddOutlined />,
//   },
//   {
//     path: EnterprisePaths.Roles,
//     label: "Roles",
//     icon: <GoldOutlined />,
//   },
//   {
//     path: EnterprisePaths.Contacts,
//     icon: <ContactsOutlined />,
//     label: "Contacts",
//   },
//   {
//     path: EnterprisePaths.Groups,
//     label: "Groups",
//     icon: <UsergroupAddOutlined />,
//   },
//   {
//     path: EnterprisePaths.Templates,
//     label: "Templates",
//     icon: <FilePdfOutlined />,
//   },
//   {
//     path: EnterprisePaths.Library,
//     label: "Library",
//     icon: <SolutionOutlined />,
//   },
//   {
//     path: EnterprisePaths.ElectronicSeals,
//     label: "Electronic Seals",
//     icon: <RadiusSettingOutlined />,
//   },
//   {
//     path: EnterprisePaths.Notifications,
//     label: "Notification Settings",
//     icon: <BellOutlined />,
//   },
//   {
//     path: EnterprisePaths.Integrations,
//     label: "Integrations",
//     icon: <LinkOutlined />,
//   },
//   {
//     path: EnterprisePaths.Branding,
//     label: "Branding",
//     icon: <FormatPainterOutlined />,
//   },
//   {
//     path: EnterprisePaths.Reports,
//     label: "Reports",
//     icon: <PieChartOutlined />,
//   },
//   {
//     path: EnterprisePaths.Documents,
//     label: "Documents",
//     icon: <FileOutlined />,
//   },
//   {
//     path: EnterprisePaths.Logs,
//     label: "Logs",
//     icon: <BarChartOutlined />,
//   },
//   {
//     path: EnterprisePaths.Advanced,
//     label: "Advanced",
//     icon: <SafetyCertificateOutlined />,
//   },
// ];

export const ENTERPRISE_SETTINGS_ROUTES = [
  {
    path: EnterprisePaths.Profile,
    label: "Profile",
    icon: <UserOutlined />,
  },
  {
    path: EnterprisePaths.Users,
    label: "Users",
    icon: <UsergroupAddOutlined />,
  },
  {
    path: EnterprisePaths.Notifications,
    label: "Notification Settings",
    icon: <BellOutlined />,
  },
  {
    path: EnterprisePaths.Integrations,
    label: "Integrations",
    icon: <LinkOutlined />,
  },
  {
    path: EnterprisePaths.Branding,
    label: "Branding",
    icon: <FormatPainterOutlined />,
  },
  {
    path: EnterprisePaths.Reports,
    label: "Reports",
    icon: <PieChartOutlined />,
  },
  {
    path: EnterprisePaths.Advanced,
    label: "Advanced",
    icon: <SafetyCertificateOutlined />,
  },
];
