import { Layout, Menu } from "antd";
import { Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ENTERPRISE_SETTINGS_ROUTES } from "../constants/enterprisePaths";
import { UserAccessContext } from "../context/UserAccessContext";
import Loading from "../components/Loading";

function EnterpriseSettingsLayout() {
  const { pathname } = useLocation();
  const [defaultRoutes, setDefaultRoutes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const {
    state: { enterprise_routes_access },
  } = useContext(UserAccessContext);
  useEffect(() => {
    if (pathname === "/enterprise" || pathname === "/enterprise/") {
      navigate("/enterprise/profile");
    }
    if (enterprise_routes_access) {
      // Check if the user has access to the current pathname
      const currentPathAccess =
        enterprise_routes_access[pathname.replace("/enterprise/", "")];
      if (!currentPathAccess) {
        navigate("/");
        return;
      }
    }
  }, [enterprise_routes_access, pathname]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (enterprise_routes_access) {
          const anyFeatureEnabled = Object.values(
            enterprise_routes_access
          ).some((value) => value === true);

          if (!anyFeatureEnabled) {
            navigate("/");
            return;
          }
          const filteredRoutes = ENTERPRISE_SETTINGS_ROUTES.filter((route) => {
            const routePath = route.path.replace("/enterprise/", "");
            const access = enterprise_routes_access[routePath];
            return access !== null && access !== undefined && access !== false;
          });
          setDefaultRoutes(filteredRoutes);
        }
      } catch (error) {
        setError(error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };
    fetchData();
  }, [enterprise_routes_access]);

  const onClick = (e) => {
    navigate(e?.key || "/enterprise/profile");
  };

  return (
    <Layout className="flex-row max-h-[85vh] w-full p-[3vh] gap-10 overflow-scroll-hidden">
      {loading && <Loading spinning={loading} />}
      {error && <div>Error: {error.message}</div>}
      {!loading && !error && (
        <>
          <Menu
            onClick={onClick}
            style={{
              width: "20vw",
              display: "flex",
              height: "50%",
              flexDirection: "column",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
            defaultSelectedKeys={pathname}
            mode="vertical"
            items={defaultRoutes.map((route) => ({
              key: route.path,
              label: <>{route.label}</>,
              icon: <span className="mr-1">{route.icon}</span>,
            }))}
          />

          <div className="p-[2em]  rounded-lg w-full overflow-y-scroll hide__scroll">
            <Outlet />
          </div>
        </>
      )}
    </Layout>
  );
}

export default EnterpriseSettingsLayout;
