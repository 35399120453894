import { Button, Divider, Dropdown, Menu, Modal, Space, Tooltip } from "antd";
import { cloneElement, useContext, useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import editProfileImg from "../assets/images/editprofile.svg";
import logOutImg from "../assets/images/Vector.svg";
import closeCircle from "../assets/images/close-circle.svg";
import logoutImg from "../assets/images/logoutDrawer.svg";
import { PathConstants } from "../constants/paths";
import { useTranslation } from "react-i18next";
import { UserAccessContext } from "../context/UserAccessContext";

const HeaderProfileMenu = ({ t }) => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(UserAccessContext);
  const [photo, setPhoto] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  //system_settings
  const [support_email, setSupportEmail] = useState("");
  const [showEnterprise, setShowEnterprise] = useState(false);
  useEffect(() => {
    const picture = JSON.parse(sessionStorage.getItem("photo"));
    const profile = JSON.parse(sessionStorage.getItem("profile"));
    const settings = JSON.parse(sessionStorage.getItem("system_settings"));
    setPhoto(() => picture);
    setName(() => profile?.general?.user_name);
    setEmail(() => profile?.general?.user_email);
    setSupportEmail(settings?.support_email);
  }, []);
  useEffect(() => {
    if (state.enterprise_routes_access) {
      const { enterprise_routes_access } = state;
      const anyFeatureEnabled = Object.values(enterprise_routes_access).some(
        (value) => value === true
      );
      console.log("Any feature enabled :", { anyFeatureEnabled, state });
      setShowEnterprise(anyFeatureEnabled);
    }
  }, [state]);
  const getInitialProfileImage = () => {
    const names = name?.split(" ");
    return names
      ?.map((name) => name.charAt(0))
      .join("")
      .toUpperCase();
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    sessionStorage.clear();
    dispatch({
      type: "LOGOUT",
    });
    setTimeout(() => {
      navigate(PathConstants.Login);
    }, 300);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const items = [
    {
      key: "0",
      label: (
        <div className="flex items-center  justify-center ">
          <Link to={PathConstants.Settings} className="mt-3">
            <Button
              type="primary"
              className="w-[191px] h-10 bg-lime-50 rounded-[20px]"
              icon={<img src={editProfileImg} alt="" />}
            >
              {t("edit_profile")}
            </Button>
          </Link>
        </div>
      ),
      className: "bg-transparent mb-2 ",
    },
    {
      type: "divider",
    },

    showEnterprise && {
      key: "1",
      label: (
        <Link to="/enterprise/profile">
          <p className="text-zinc-900 text-sm font-normal font-['Poppins'] leading-snug">
            {t("enterprise_settings")}
          </p>
        </Link>
      ),
      className: "px-4 py-2 mt-2",
    },
    {
      key: "2",
      label: (
        <Link to={PathConstants.Settings}>
          <p className="text-zinc-900 text-sm font-normal font-['Poppins'] leading-snug">
            {t("settings")}
          </p>
        </Link>
      ),
      className: "px-4 py-2 mt-2",
    },
    // {
    //   key: "3",
    //   label: (
    //     <Link>
    //       <p className="text-zinc-900 text-sm font-normal font-['Poppins'] leading-snug">
    //         {t("plans")}
    //       </p>
    //     </Link>
    //   ),
    //   className: "px-4 py-2",
    // },
    {
      key: "4",
      label: (
        <Link  to={PathConstants.Activity}>
          <p className="text-zinc-900 text-sm font-normal font-['Poppins'] leading-snug">
            {t("activity")}
          </p>
        </Link>
      ),
      className: "px-4 py-2 mb-2",
    },
    {
      type: "divider",
    },
    {
      key: "5",
      label: (
        <Link to={PathConstants.Groups}>
          <p className="text-zinc-900 text-sm font-normal font-['Poppins'] leading-snug">
            {t("groups")}
          </p>
        </Link>
      ),
      className: "px-4 py-2 mt-2",
    },
    {
      key: "6",
      label: (
        <Link
          to="https://manuals.ascertia.com/SigningHub/8.4/Web/"
          target="_blank"
        >
          <p className="text-zinc-900 text-sm font-normal font-['Poppins'] leading-snug">
            {t("help")}
          </p>
        </Link>
      ),
      className: "px-4 py-2",
    },
    {
      key: "7",
      label: (
        <Link to={`mailto:${support_email}`} target="_blank">
          <p className="text-zinc-900 text-sm font-normal font-['Poppins'] leading-snug">
            {t("contact")}
          </p>
        </Link>
      ),
      className: "px-4 py-2 mb-2",
    },
    {
      type: "divider",
    },
    {
      key: "8",
      label: (
        <div className="flex items-center gap-2" onClick={showModal}>
          <img src={logoutImg} alt="profileImg" />
          <p className="text-neutral-400 text-sm font-normal font-['Poppins'] leading-snug">
            {t("logout")}
          </p>
        </div>
      ),
      className: "px-4 py-2 mt-2",
    },
  ];

  return (
    <>
      <div className="flex">
        {photo ? (
          <img
            className="profile-pic"
            src={`data:image/png;base64,${photo}`}
            alt="side"
          />
        ) : (
          <div className="custom-profile-image">{getInitialProfileImage()}</div>
        )}
        <h2>
          {t("hello")} , {name}
          <br />
          <span className="profile_email">{email}</span>
        </h2>
      </div>
      <Dropdown
        placement="bottomRight"
        arrow
        trigger="click"
        menu={{
          items,
        }}
        dropdownRender={(menu) => (
          <div className="w-72  bg-white rounded-lg border border-neutral-200 py-4 px-2">
            <div className="flex flex-col items-center gap-1">
              {photo ? (
                <img
                  className="profile-pic"
                  src={`data:image/png;base64,${photo}`}
                  alt="side"
                />
              ) : (
                <div className="custom-profile-image">
                  {getInitialProfileImage()}
                </div>
              )}
              <p className="text-zinc-900 text-base font-semibold font-['Poppins'] leading-normal">
                {name}
              </p>
              <span className="text-neutral-400 text-sm font-normal font-['Poppins'] leading-snug">
                {email}
              </span>
            </div>
            {cloneElement(menu, {
              style: {
                boxShadow: "none",
                width: "100%",
              },
            })}
          </div>
        )}
      >
        <DownOutlined />
      </Dropdown>
      <Modal
        className="log-out-modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t("logout")}
        cancelText={t("cancel_btn")}
        closeIcon={<img src={closeCircle} alt="" />}
      >
        <div className="flex items-center justify-center gap-2 flex-col mb-8 mt-4">
          <img src={logOutImg} alt="" />
          <p className="text-center text-zinc-900 text-2xl font-semibold font-['Poppins'] leading-loose">
            {t("logout")}
          </p>
          <p className="w-[345px] text-center text-black text-base font-medium font-['Poppins'] leading-normal">
            {t("logout_msg")}
          </p>
        </div>
      </Modal>
    </>
  );
};
export default HeaderProfileMenu;
