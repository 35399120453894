// PopupContext.js
import React, { createContext, useState, useContext } from "react";

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const [popup, setPopup] = useState(null);

  const closePopup = () => {
    document.body.style.overflow = "unset";
    setPopup(null);
  };

  const openPopup = (popUpProperties) => {
    document.body.style.overflow = "hidden";
    setPopup({
      content: popUpProperties.content,
      title: popUpProperties.title,
      width: popUpProperties.width || "43em",
    });
  };

  return (
    <PopupContext.Provider value={{ popup, openPopup, closePopup }}>
      {children}
    </PopupContext.Provider>
  );
};

export const usePopup = () => {
  return useContext(PopupContext);
};
