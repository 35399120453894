import { Navigate, useLocation } from "react-router-dom";
import { PathConstants } from "../constants/paths";
import MainLayout from "./MainLayout";
import AuthLayout from "./AuthLayout";

export default function ProtectedLoginRoute() {
  const location = useLocation();
  const password_token = sessionStorage.getItem("password_token");

  return !password_token ? (
    <AuthLayout />
  ) : (
    <Navigate to={PathConstants.Home} replace state={{ from: location }} />
  );
}
