import { Layout } from "antd";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { PathConstants } from "../constants/paths";
import SiteFooter from "../components/SiteFooter";

export default function AuthLayout() {
  return (
    <Layout>
      <Outlet />
      {/* <SiteFooter /> */}
    </Layout>
  );
}
