import { useQuery, useMutation, useQueryClient } from "react-query";
import { message } from "antd";
import { BASE_URL } from "../global";
import { useState } from "react";

const useApiRequest = () => {
  const queryClient = useQueryClient();
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  // Define a function to handle GET requests
  const Get = (key, url, options = {}, onSuccessFun) => {
    return useQuery({
      queryKey: key,
      // variables: {
      //   date: staticDate,
      // },

      retry: false,
      // staleTime: Infinity,
      // cacheTime: Infinity,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      queryFn: () => {
        return fetch(`${BASE_URL}/v4/${url}`, {
          ...options,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => res.json())
          .catch((err) => {
            console.log("err", err);
            throw err; // rethrow the error for the mutation to handle
          });
      },

      onError: (error, variables, context) => {
        // An error happened!
        message.error(error);
      },
      onSuccess: (data, variables, context) => {
        // You can return the data or perform additional actions with it
        // onSuccessFun();
        console.log("variables", variables);
        console.log("onSuccessFun", onSuccessFun);
        return data;
      },
      onSettled: (data, error, variables, context) => {
        // Error or success... doesn't matter!
        if (data?.Message) {
          message.error(data?.Message);
        }
      },
    });
  };

  // Define a function to handle mutations (POST, PUT, DELETE, etc.)
  const Mutate = (key, method = "POST", options = {}) => {
    const token = JSON.parse(sessionStorage.getItem("password_token"));

    const mutation = useMutation({
      mutationKey: key,
      mutationFn: (params) => {
        const requestOptions = {
          ...options,
          method,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            ...params.header,
          },
        };

        // Add body only if it's not a DELETE request
        if (params.hasOwnProperty("data")) {
          requestOptions.body = JSON.stringify(params.data);
        }

        return fetch(`${BASE_URL}/v4/${params.url}`, requestOptions)
          .then(async (res) => res)
          .catch((err) => {
            console.log("err", err);
            throw err; // rethrow the error for the mutation to handle
          });
      },

      onError: (error, variables, context) => {
        // An error happened!
        message.error(error);
      },
      onSuccess: (data, variables, context) => {
        return data;
      },
      onSettled: async (data, error, variables, context) => {
        // console.log('onSettled api data',data);
        // Error or success... doesn't matter!
        if (!data.ok) {
          const res = await data.json();
          message.error(res.Message);
        }
        // return data;
      },
    });

    return mutation;
  };

  return {
    Get,
    Mutate,
  };
};

export default useApiRequest;
