import home_icon from "../assets/images/home.svg";
import back_icon from "../assets/images/back.svg";
import back_Arrow from "../assets/images/header_arrow_down.svg";
import { Progress, Steps, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { PathConstants } from "../constants/paths";
import { useMediaQuery } from "react-responsive";
const WorkFlowSteps = ({ t }) => {
  const isMobile = useMediaQuery({ maxWidth: 850 });
  const location = useLocation();
  const [current, setCurrent] = useState(0);
  const [steps, setSteps] = useState(4);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    switch (
      pathname.substring(pathname.lastIndexOf("/") + 1, pathname.length)
    ) {
      case "documents":
        setCurrent(() => 0);
        setSteps(4);

        break;
      case "recipients":
        setCurrent(() => 1);
        setSteps(3);

        break;
      case "prepare":
        setCurrent(() => 2);
        setSteps(2);

        break;
      case "send":
        setCurrent(() => 3);
        setSteps(1);
        break;
      default:
        setCurrent(() => 0);
        setSteps(0);
        break;
    }
  }, [pathname]);
  const items = [
    {
      title: t("documents"),
      description: t("documents_step_description"),
      key: 0,
    },
    {
      title: t("recipient_step"),
      description: t("recipient_step_description"),
      key: 1,
    },
    {
      title: t("prepare_step"),
      description: t("prepare_step_description"),
      key: 2,
    },
    {
      title: t("send_step"),
      description: t("send_step_description"),
      key: 3,
    },
  ];

  const goBack = () => {
    navigate(-1);
  };
  const goHome = () => {
    navigate(PathConstants.Home);
  };

  return (
    <div
      className={`${
        location.pathname.includes("prepare")
          ? "px-[10%] py-4 bg-[#F8F8F8]"
          : isMobile
          ? "px-0"
          : "px-[10%]"
      } `}
    >
      {!location.pathname.includes("prepare") && (
        <div
          className={`flex mb-[20px] p-2 ${
            isMobile ? "bg-neutral-100 px-[5%]" : ""
          }`}
        >
          <Tooltip title={t("home")}>
            <img
              src={home_icon}
              alt="home"
              className="mr-[10px]"
              onClick={() => goHome()}
            />
          </Tooltip>
          <Tooltip title={t("back")}>
            <img
              src={back_icon}
              alt="back"
              className="mr-[10px]"
              onClick={() => goBack()}
            />
          </Tooltip>

          <h2
            className={
              isMobile
                ? "text-zinc-900 text-sm font-medium font-['Poppins'] leading-[38px]"
                : "text-[24px] font-[500]"
            }
          >
            {t("step_header")}
          </h2>
        </div>
      )}
      {isMobile ? (
        <div className="w-full flex items-center justify-start gap-4 px-[5%]">
          <img
            src={back_Arrow}
            alt=""
            onClick={() => navigate(-1)}
            className=" rtl:rotate-180"
          />
          <Progress
            type="circle"
            percent={100 / steps}
            size="small" // Adjust the width based on your preference
            format={() => `${current + 1} of 4`}
          />
          <div className="flex  flex-col gap-1">
            <p className="text-zinc-900 text-base font-medium font-['Poppins'] leading-normal">
              {items[current].title}
            </p>
            <p className="text-neutral-400 text-sm font-normal font-['Poppins'] leading-snug">
              next step: {items[current + 1].description}
            </p>
          </div>
        </div>
      ) : (
        <Steps
          size="small"
          current={current}
          items={items}
          id="documents_steps"
        />
      )}
    </div>
  );
};
export default WorkFlowSteps;
