import { createContext, useReducer } from "react";

export const UserAccessContext = createContext();

const initialState = {
  roleAccess: {},
  loadingUserAccess: true,
};

const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGOUT":
      return initialState;
    case "UPDATE":
      const {
        payload: {
          user_setting: {
            signatures,
            groups,
            templates,
            library,
            advanced,
            legal_notices,
            delegate,
            cloud_drives,
            emails,
          },
          enterprise_setting: {
            profile: enterpriseProfile,
            roles,
            users,
            groups: enterpriseGroups,
            templates: enterpriseTemplates,
            library: enterpriseLibrary,
            integration,
            branding,
            emails: enterpriseNotifications,
            report,
            advanced: enterpriseAdvanced,
          },
        },
      } = action;
      return {
        ...state,
        roleAccess: action.payload,
        loadingUserAccess: false,
        personal_routes_access: {
          //Profile always true for personal user
          profile: true,
          signatures,
          contacts: groups,
          groups: groups,
          templates: templates,
          library,
          notifications: emails,
          cloud: cloud_drives,
          legal_notices,
          delegate,
          advanced,
        },
        enterprise_routes_access: {
          profile: enterpriseProfile,
          users,
          roles,
          contacts: enterpriseGroups,
          groups: enterpriseGroups,
          templates: enterpriseTemplates,
          library: enterpriseLibrary,
          ElectronicSeals: null,
          notifications: enterpriseNotifications,
          integration,
          branding,
          reports: report,
          documents: null,
          logs: null,
          advanced: enterpriseAdvanced,
        },
      };
    default:
      // Handle other action types if needed
      return state;
  }
};
export const UserAccessContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  return (
    <UserAccessContext.Provider value={{ state, dispatch }}>
      {children}
    </UserAccessContext.Provider>
  );
};
