import { Badge, Divider, Dropdown, Menu, Tooltip } from "antd";
import { Header } from "antd/es/layout/layout";
import DefaultLogo from "../assets/images/logo.svg";
import DefaultSmallLogo from "../assets/images/small_logo.png";
import bell from "../assets/images/bell.svg";

import errorNotifiIcon from "../assets/images/errorNotification.svg";
import successNotifiIcon from "../assets/images/infoNotification.svg";
import logOut from "../assets/images/logOut.svg";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import HeaderProfileMenu from "./HeaderProfileMenu";
import { useTranslation } from "react-i18next";
import { PathConstants } from "../constants/paths";
import { useMediaQuery } from "react-responsive";
import {
  get_notifications,
  mark_notifications_as_read,
} from "../services/api/Account/scripts";
import moment from "moment";
import SiteHeaderMobile from "./SiteHiderMobile";
const SiteHeader = () => {
  // resopnsive
  const getNotifications = async () => {
    setNotifications([]);
    setUnreadNotifications([]);
    setBadge(0);
    const res = await get_notifications(1000, 1);
    res?.notifications?.map((item) => {
      setNotifications((pervState) => {
        return [...pervState, item];
      });
      if (item?.unread) {
        setUnreadNotifications((pervState) => {
          return [...pervState, item];
        });
        setBadge((pervState) => {
          return pervState + 1;
        });
      } else {
      }
    });
  };
  const notificationMarkAsRead = async () => {
    await mark_notifications_as_read();
    setBadge(0);
  };
  const isMobile = useMediaQuery({ maxWidth: 900 });
  const isTablet = useMediaQuery({ maxWidth: 1100 });
  const [languages, setlanguages] = useState([]);
  const [defaultLang, setDefault] = useState("en-US");
  const [photo, setPhoto] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [showMenu, setShowMenue] = useState(true);
  const { t } = useTranslation("translation", { keyPrefix: "languages" });
  const [current, setCurrent] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [badge, setBadge] = useState(0);

  const { pathname } = useLocation();
  const items = [
    {
      label: <Link to={PathConstants.Home}>{t("documents")}</Link>,
      key: "documents",
    },
    {
      label: <Link to={PathConstants.Templates}>{t("templates")}</Link>,
      key: "templates",
    },
    {
      label: <Link to={PathConstants.Library}>{t("library")}</Link>,
      key: "library",
    },
    {
      label: <Link to={PathConstants.Contacts}>{t("contacts")}</Link>,
      key: "contacts",
    },
  ];
  const menuePaths = ["/", "/templates", "/library", "/contacts"];

  useEffect(() => {
    setShowMenue(() => menuePaths.indexOf(pathname) > -1);
    switch (pathname) {
      case "/":
        setCurrent(() => "documents");
        break;
      case PathConstants.Templates:
        setCurrent(() => "templates");
        break;
      case PathConstants.Library:
        setCurrent(() => "library");
        break;
      case PathConstants.Contacts:
        setCurrent(() => "contacts");
        break;
      default:
        setCurrent(() => "");
        break;
    }
  }, [pathname]);
  useEffect(() => {
    const picture = JSON.parse(sessionStorage.getItem("photo"));
    const profile = JSON.parse(sessionStorage.getItem("profile"));
    const langs = JSON.parse(sessionStorage.getItem("languages"));
    console.log("langs", langs);
    setDefault(() => langs?.default_language);
    setlanguages(() =>
      langs
        ? Object.keys(langs?.supported_languages).map((key) => {
            return {
              key: key,
              label: langs?.supported_languages[key],
              value: key,
            };
          })
        : []
    );
    setPhoto(() => picture);
    setName(() => profile?.general?.user_name);
    setEmail(() => profile?.general?.user_email);
    getNotifications();
  }, [pathname]);
  const getInitialProfileImage = () => {
    const names = name?.split(" ");
    return names
      ?.map((name) => name.charAt(0))
      .join("")
      .toUpperCase();
  };
  const handleChange = (value) => {};

  return (
    <>
      {!isMobile ? (
        <Header className="px-[3.8%] flex items-center justify-start flex-row ">
          <Tooltip title={t("home")}>
            <Link to={PathConstants.Home}>
              <img
                id="logo"
                src={showMenu && !isTablet ? DefaultLogo : DefaultSmallLogo}
                alt="home"
              />
            </Link>
          </Tooltip>
          {showMenu && (
            <Menu
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
              className="ltr:ml-8 rtl:mr-8 main-menu-header"
            />
          )}
          <div className="rtl:mr-auto ltr:ml-auto flex ">
            <Tooltip title={t("notifications")}></Tooltip>
            <Dropdown
              placement="bottom"
              arrow
              dropdownRender={() => (
                <div
                  className="py-1 border  rounded-3xl shadow bg-[#FFFFFF]
                min-w-[280px] max-w-[400px]   "
                >
                  <ul className="min-h-[100px] max-h-[350px]  w-[90%] mx-auto flex flex-col overflow-y-scroll custom__scroll hide__scroll">
                    {notifications?.length === 0 ? (
                      <div className="w-full h-full flex items-center justify-center m-auto">
                        <p>No notification</p>
                      </div>
                    ) : (
                      notifications?.map((notification, index) => (
                        <div key={index}>
                          <div className="p-2 flex gap-4">
                            <img
                              width="25px"
                              height="25px"
                              src={
                                notification.type === "error"
                                  ? errorNotifiIcon
                                  : successNotifiIcon
                              }
                              alt="notifiIcon"
                            />
                            <div>
                              <p className="font-medium">
                                {notification.activity_label}
                              </p>
                              <p className="mb-2 font-medium">
                                {notification.information.value}
                              </p>

                              <span className="font-normal text-gray-500">
                                {moment(notification.date_time).format(
                                  "D MMM YYYY, hh:mm A"
                                )}
                              </span>
                            </div>
                          </div>
                          {index < notifications.length - 1 && (
                            <Divider className="my-0" />
                          )}
                        </div>
                      ))
                    )}
                  </ul>
                </div>
              )}
              trigger="click"
            >
              <Badge className="mx-4" count={badge} overflowCount={10}>
                <img
                  src={bell}
                  alt="bell"
                  id="bell"
                  onClick={() => notificationMarkAsRead()}
                />
              </Badge>
            </Dropdown>
            <HeaderProfileMenu t={t} />
          </div>
        </Header>
      ) : (
        <SiteHeaderMobile
          t={t}
          photo={photo}
          getInitialProfileImage={getInitialProfileImage}
          name={name}
          email={email}
          defaultLang={defaultLang}
          handleChange={handleChange}
          languages={languages}
          badge={badge}
          notifications={notifications}
          notificationMarkAsRead={mark_notifications_as_read}
        />
      )}
    </>
  );
};

export default SiteHeader;
