import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
//To load the translation files

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    whitelist: ["en", "ar"], //Array of abbrevations of the languages
    interpolation: {
      escapeValue: false,
    },
    ns: ["translation"], //Names of the translation files
    detection: {
      order: ["localStorage", "cookie"],
      caches: ["localStorage", "cookie"],
    },
    // saveMissing: true,
    // saveMissingTo: "all",
  });

export default i18n;
