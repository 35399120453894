import { Content } from "antd/es/layout/layout";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Loading from "./Loading";

const SiteContent = () => {
  return (
    <Content
    // className={` ${pathname.includes("/prepare") ? "p-0" : "px-[5%]"}`}
    >
      <Suspense fallback={<Loading />}>
        <Outlet />
      </Suspense>
    </Content>
  );
};
export default SiteContent;
