import { Header } from "antd/es/layout/layout";
import { PathConstants } from "../constants/paths";
import { useState } from "react";
import { Button, Divider, Drawer, Modal, Select, Tooltip } from "antd";
import { Link, Navigate, useNavigate } from "react-router-dom";
import logOutImg from "../assets/images/Vector.svg";
import closeCircle from "../assets/images/close-circle.svg";
import logoMobile from "../assets/images/home-mobile.png";
import acountSttingMob from "../assets/images/acount-setting-mob.svg";
import notificationMob from "../assets/images/notification.svg";
import documentsMob from "../assets/images/documents-mob.svg";
import templatesMob from "../assets/images/templates-mov.svg";
import libraryMob from "../assets/images/library-mob.svg";
import contactUsMob from "../assets/images/conatct-us-mob.svg";
import HamburgerMobile from "../assets/images/Hamburger-Button-mobile.svg";
import clooseIconDrawer from "../assets/images/close-icon-drawer.svg";
import settingsArrow from "../assets/images/settings-arrow.svg";
import accountSettingsArrow from "../assets/images/acount-setting-arrow.svg";
import logOut from "../assets/images/logOut.svg";
import errorNotifiIcon from "../assets/images/errorNotification.svg";
import successNotifiIcon from "../assets/images/infoNotification.svg";
import moment from "moment";
const SiteHeaderMobile = ({
  t,
  photo,
  getInitialProfileImage,
  name,
  email,
  defaultLang,
  handleChange,
  languages,
  badge,
  notificationMarkAsRead,
  notifications,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isModalLogOutOpen, setisModalLogOutOpen] = useState(false);
  const [isModalNotificationsOpen, setIsModalNotificationsOpen] =
    useState(false);
  const [openAccountSettingsMobile, setOpenAccountSettingsMobile] =
    useState(true);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const showNAccountSettingsMobile = () => {
    setOpenAccountSettingsMobile(!openAccountSettingsMobile);
  };
  const handleOkLogOut = () => {
    setisModalLogOutOpen(false);
    sessionStorage.removeItem("password_token");
    setTimeout(() => {
      navigate(PathConstants.Login);
    }, 300);
  };
  const handleCancelLogOut = () => {
    setisModalLogOutOpen(false);
  };
  const showModalLogOut = () => {
    setisModalLogOutOpen(true);
  };

  const handleCancelNotifications = () => {
    setIsModalNotificationsOpen(false);
  };
  const showModalNotifications = () => {
    setIsModalNotificationsOpen(true);
  };
  return (
    <Header className="px-[3.8%] flex-row	  items-center justify-between">
      <Tooltip title={t("home")}>
        <Link to={PathConstants.Home}>
          <img src={logoMobile} alt="home" />
        </Link>
      </Tooltip>
      <div className="flex items-center justify-between gap-8">
        <Tooltip title={t("profile")}>
          <div>
            {photo ? (
              <img
                className="profile-pic"
                src={`data:image/png;base64,${photo}`}
                alt="side"
              />
            ) : (
              <div className="custom-profile-image">
                {getInitialProfileImage()}
              </div>
            )}
          </div>
        </Tooltip>
        <img
          src={HamburgerMobile}
          alt="notification-Menu"
          onClick={showDrawer}
        />
      </div>
      <Drawer
        closeIcon={
          openAccountSettingsMobile ? (
            <img src={clooseIconDrawer} alt="" />
          ) : (
            false
          )
        }
        classNames={{
          header: "main-drawer-header", // Custom class for header
        }}
        title={
          openAccountSettingsMobile ? (
            <div className="flex items-center justify-end gap-4 mr-5">
              <div>
                {photo ? (
                  <img
                    className="profile-pic"
                    src={`data:image/png;base64,${photo}`}
                    alt="side"
                  />
                ) : (
                  <div className="custom-profile-image">
                    {getInitialProfileImage()}
                  </div>
                )}
              </div>
              <h2 className="text-black text-sm font-medium font-['Inter']">
                {t("hello")} , {name}
                <br />
                <span className="text-stone-300 text-[11px] font-medium font-['Poppins']">
                  {email}
                </span>
              </h2>
            </div>
          ) : (
            <div className="flex items-center justify-between mx-[1%]">
              <img
                src={settingsArrow}
                alt=""
                onClick={showNAccountSettingsMobile}
              />
              <div className="flex items-center justify-end gap-4 mr-5">
                <div>
                  {photo ? (
                    <img
                      className="profile-pic"
                      src={`data:image/png;base64,${photo}`}
                      alt="side"
                    />
                  ) : (
                    <div className="custom-profile-image">
                      {getInitialProfileImage()}
                    </div>
                  )}
                </div>
                <h2 className="text-black text-sm font-medium font-['Inter']">
                  {t("hello")} , {name}
                  <br />
                  <span className="text-stone-300 text-[11px] font-medium font-['Poppins']">
                    {email}
                  </span>
                </h2>
              </div>
            </div>
          )
        }
        onClose={onClose}
        open={open}
      >
        {openAccountSettingsMobile ? (
          <div className="">
            <div className="flex flex-col gap-4 w-[90%]  mb-8 ml-[10%]">
              <div
                className="flex items-center  justify-between w-full"
                onClick={showNAccountSettingsMobile}
              >
                <div className="flex items-center gap-4">
                  <img src={acountSttingMob} alt="" />
                  <p className="text-black text-base font-normal font-['Poppins']">
                    Account Settings
                  </p>
                </div>
                <img src={accountSettingsArrow} alt="" />
              </div>
              <div
                className="flex items-center  justify-between w-full"
                onClick={showModalNotifications}
              >
                <div className="flex items-center gap-4 cursor-pointer">
                  <img src={notificationMob} alt="" className="ml-[-5px]" />
                  <p className="text-black text-base font-normal font-['Poppins']">
                    Notifications
                  </p>
                </div>
                <p className="w-[19px] h-[19px] bg-red-600 rounded-full flex items-center justify-center text-white text-[15px] font-normal font-['Poppins']">
                  {badge}
                </p>
              </div>
              <div className="flex items-center gap-4 cursor-pointer">
                <img src={documentsMob} alt="" />
                <p className="text-black text-base font-normal font-['Poppins']">
                  Documents
                </p>
              </div>
              <div className="flex items-center gap-4 cursor-pointer">
                <img src={templatesMob} alt="" />
                <p className="text-black text-base font-normal font-['Poppins']">
                  Templates
                </p>
              </div>
              <div className="flex items-center gap-4 cursor-pointer">
                <img src={libraryMob} alt="" />
                <p className="text-black text-base font-normal font-['Poppins']">
                  Library
                </p>
              </div>
              <div className="flex items-center  gap-4 cursor-pointer">
                <img src={contactUsMob} alt="" />
                <p className="text-black text-base font-normal font-['Poppins']">
                  Contact us
                </p>
              </div>
            </div>
            <Divider
              style={{
                backgroundColor: "#D2D2D2",
              }}
            />
            <div className="flex flex-col gap-4 w-[90%] mb-8 ml-[10%]">
              <a
                className="text-zinc-900 text-xs font-normal font-['Poppins']"
                href="/about-us"
              >
                About us
              </a>
              <a
                className="text-zinc-900 text-xs font-normal font-['Poppins']"
                href="/terms"
              >
                {t("terms")}
              </a>
              <a
                className="text-zinc-900 text-xs font-normal font-['Poppins']"
                href="/Privacy"
              >
                {t("privacy")}
              </a>
              <a
                className="text-zinc-900 text-xs font-normal font-['Poppins']"
                href="/help"
              >
                Help
              </a>
              <Select
                defaultValue={defaultLang}
                className="drawer-slector"
                style={{
                  width: 120,
                }}
                bordered={false}
                onChange={handleChange}
                options={languages}
              />
            </div>
          </div>
        ) : (
          <div className="h-full flex flex-col justify-between ">
            <div className="flex flex-col gap-4 w-[90%]  mb-8 ml-[10%]">
              <div className="flex items-center  justify-between w-full">
                <p className="text-black text-base font-normal font-['Poppins']">
                  My Profile
                </p>
              </div>
              <div className="flex items-center  justify-between w-full">
                <p className="text-black text-base font-normal font-['Poppins']">
                  Account Settings
                </p>
              </div>
              <div className="flex items-center  justify-between w-full">
                <p className="text-black text-base font-normal font-['Poppins']">
                  Notification Settings
                </p>
              </div>
              <div className="flex items-center  justify-between w-full">
                <p className="text-black text-base font-normal font-['Poppins']">
                  Plans and Billing
                </p>
              </div>
              <div className="flex items-center  justify-between w-full">
                <p className="text-black text-base font-normal font-['Poppins']">
                  Activity
                </p>
              </div>
              <div className="flex items-center  justify-between w-full">
                <p className="text-black text-base font-normal font-['Poppins']">
                  Contacts
                </p>
              </div>
              <div className="flex items-center  justify-between w-full">
                <p className="text-black text-base font-normal font-['Poppins']">
                  My Teams
                </p>
              </div>
              <div className="flex items-center  justify-between w-full">
                <p className="text-black text-base font-normal font-['Poppins']">
                  Archives
                </p>
              </div>
            </div>
            <div className="">
              <Divider
                style={{
                  backgroundColor: "#D2D2D2",
                }}
              />
              <div
                className="flex gap-2 w-[90%] ml-[10%]"
                onClick={showModalLogOut}
              >
                <img src={logOut} alt="" />
                <p className="text-neutral-400 text-sm font-normal font-['Poppins'] leading-snug">
                  Logout
                </p>
              </div>
            </div>
          </div>
        )}
      </Drawer>
      <Modal
        className="log-out-modal"
        open={isModalLogOutOpen}
        onOk={handleOkLogOut}
        onCancel={handleCancelLogOut}
        okText={t("logout")}
        cancelText={t("cancel_btn")}
        closeIcon={<img src={closeCircle} alt="" />}
      >
        <div className="flex items-center justify-center gap-2 flex-col mb-8 mt-4">
          <img src={logOutImg} alt="" />
          <p className="text-center text-zinc-900 text-2xl font-semibold font-['Poppins'] leading-loose">
            {t("logout")}
          </p>
          <p className="w-[345px] text-center text-black text-base font-medium font-['Poppins'] leading-normal">
            {t("logout_msg")}
          </p>
        </div>
      </Modal>
      <Modal
        className="log-out-modal"
        open={isModalNotificationsOpen}
        footer={null}
        closeIcon={<img src={closeCircle} alt="" />}
      >
        <div className="mt-10 min-w-[280px] max-w-[400px]">
          <ul className="min-h-[100px] max-h-[350px]  w-[90%] mx-auto flex flex-col overflow-y-scroll custom__scroll hide__scroll">
            {notifications?.length === 0 ? (
              <div className="w-full h-full flex items-center justify-center m-auto">
                <p>No notification</p>
              </div>
            ) : (
              notifications?.map((notification, index) => (
                <div key={index}>
                  <div className="p-2 flex gap-4">
                    <img
                      width="25px"
                      height="25px"
                      src={
                        notification.type === "error"
                          ? errorNotifiIcon
                          : successNotifiIcon
                      }
                      alt="notifiIcon"
                    />
                    <div>
                      <p className="font-medium">
                        {notification.activity_label}
                      </p>
                      <p className="mb-2 font-medium">
                        {notification.information.value}
                      </p>

                      <span className="font-normal text-gray-500">
                        {moment(notification.date_time).format(
                          "D MMM YYYY, hh:mm A"
                        )}
                      </span>
                    </div>
                  </div>
                  {index < notifications.length - 1 && (
                    <Divider className="my-0" />
                  )}
                </div>
              ))
            )}
          </ul>
          <div className="flex items-center justify-center mt-6">
            <Button
              type="primary"
              className="bg-black text-white"
              onClick={handleCancelNotifications}
            >
              {t("cancel_btn")}
            </Button>
          </div>
        </div>
      </Modal>
    </Header>
  );
};

export default SiteHeaderMobile;
