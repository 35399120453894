export const PathConstants = {
  Login: "/login",
  SignUp: "/sign-up",
  Home: "/",
  Documents: "/documents",
  Templates: "/templates",
  Library: "/library",
  Contacts: "/contacts",
  Contact_us: "/contactUs",
  Settings: "/settings",
  Groups: "/teams",
  Activity: "/activity",

  ProfileSettings: "/settings/profile",
  SignatureSettings: "/settings/signature",
  NotificationSettings: "/settings/notification",
  CloudStorageSettings: "/settings/cloudStorage",
  LegalNoticeSettings: "/settings/legalNotice",
  DelegateSettengs: "/settings/delegate",
  DeleteAccountSettengs: "/settings/deleteAccount",
  Recipients: "/documents/:packageId/recipients",
  Prepare: "/documents/:packageId/recipients/prepare",
  Send: "/documents/:packageId/recipients/prepare/send",
  ViewDoc: "/documents/:packageId/view",
  Sign: "/documents/:packageId/sign",
  terms: "/terms",
  Privacy: "/Privacy",
  //   REPORT_ANALYSIS: "reports/:reportId/analysis",
  // ...
};

// export const EnterprisePaths = {
//   Profile: "/enterprise/profile",
//   Users: "/enterprise/users",
//   Roles: "/enterprise/roles",
//   Contacts: "/enterprise/contacts",
//   Groups: "/enterprise/groups",
//   Templates: "/enterprise/templates",
//   ElectronicSeals: "/enterprise/ElectronicSeals",
//   Notifications: "/enterprise/notifications",
//   Integrations: "/enterprise/integration",
//   Branding: "/enterprise/branding",
//   Reports: "/enterprise/reports",
//   Library: "/enterprise/library",
//   Documents: "/enterprise/documents",
//   Logs: "/enterprise/logs",
//   Advanced: "/enterprise/advanced",
// };
export const EnterprisePaths = {
  Profile: "/enterprise/profile",
  Users: "/enterprise/users",
  Notifications: "/enterprise/notifications",
  Integrations: "/enterprise/integration",
  Branding: "/enterprise/branding",
  Reports: "/enterprise/reports",
  Advanced: "/enterprise/advanced",
};
