import { CLIENT_ID, CLIENT_SECRET, BASE_URL } from "../../global";

/**
 * name: client_credentials
 * args: none
 * return: promise response
 * response contains access_token
 */
export const client_credentials = async () => {
  const postData = {
    grant_type: "client_credentials",
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };
  var formBody = [];
  for (var property in postData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(postData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  try {
    const res = await fetch(`${BASE_URL}/authenticate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    });

    if (!res.ok) {

      throw new Error(res.status);    
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;

  }
};

/**
 *name: password_authentication
 *args: signinghub username , password (strings)
 *return: promise response
 * response contains access_token
 */
export const password_authentication = async (username, password) => {
  const postData = {
    grant_type: "password",
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    username: username,
    password: password,
  };
  var formBody = [];
  for (var property in postData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(postData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  try {
    const res = await fetch(`${BASE_URL}/authenticate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formBody,
    });

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 *name: scope_authentication
 * args: signinghib email , access token returned from (client_credentials)
 * return promise respones
 * response contains access_token
 */
export const scope_authentication = async (email, client_credentials_token) => {
  const postData = {
    user_email: email,
  };
  const token = client_credentials_token; // client_credentials
  try {
    const res = await fetch(`${BASE_URL}/v4/authenticate/scope`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 * name: kerberos_authentication
 * args: access token returned from (client_credentials)
 * return promise reponse
 *
 */
export const kerberos_authentication = async (client_credentials_token) => {
  const postData = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    profile_name: "",
  };

  const token = client_credentials_token; // client_credentials
  try {
    const res = await fetch(`${BASE_URL}/authenticate/kerberos`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 * name: single_sign_on_authentication
 * args: access token returned from (client_credentials) , sign in method data (token ,code , method , prodile_name , profile_id)
 * return promise response
 * sign in with some other method
 *
 */
export const single_sign_on_authentication = async (
  token,
  code,
  method,
  profile_name,
  profile_id
) => {
  const postData = {
  
    method: method,
    profile_name: profile_name,
    profile_id: profile_id,
  };

  const auth_token = JSON.parse(sessionStorage.getItem("client_credentials_token"));
  // client_credentials
   console.log(auth_token)
  try {
    const res = await fetch(`${BASE_URL}/authenticate/sso`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${auth_token}`,
      },
      body: JSON.stringify(postData),
    });

    if (!res.ok) {
      const body = await res.json();
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 * name: oTP_login_authentication
 * args: access_token returned from password_authentication , mobile phone number
 * return promise response
 * send otp method
 */
export const oTP_login_authentication = async (mobile) => {
  const postData = {
    mobile_number: mobile,
    method: "SMS",
  };
  const token = JSON.parse(sessionStorage.getItem("password_token"));
  const res = await fetch(`${BASE_URL}/v4/authentication/otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(postData),
  });

  return res;
};

/**
 * name:pre_login_authentication
 * args: access token returned from (client_credentials) , and signinghub username
 * return promise response
 *
 */
export const pre_login_authentication = async (
  client_credentials_access_token,
  username
) => {
  const postData = {
    username: username,
  };
  const token = client_credentials_access_token; // client_credentials_access_token
  try {
    const res = await fetch(`${BASE_URL}/authenticate/pre`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    });

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 *  args: none
 *  return promise response
 *  get the html content of terms and privacy policy page
 *
 */
export const get_service_agreements = async () => {
  try {
    const res = await fetch(`${BASE_URL}/v4/terms`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 * name: get_Public_authentication_profiles
 * args: access token returned from (client_credentials)
 * return promise response
 * get list of auth methods
 *
 */
export const get_Public_authentication_profiles = async () => {
  const token = JSON.parse(sessionStorage.getItem("client_credentials_token"));

  try {
    const res = await fetch(
      `${BASE_URL}/v4/system/configurations/profiles/auth`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error(res.status);
    }
    const body = await res.json();
    return body;
  } catch (error) {
    throw error;
  }
};

/**
 * name: revoke_refresh_tokens
 * args access token returned from (client_credentials) ,  refresh_token to be deleted
 * return promise response
 * delete some token
 *
 */
export const revoke_refresh_tokens = async (
  client_credentials_token,
  refresh_token
) => {
  const postData = {
    refresh_token: refresh_token,
    token_type_hint: "refresh_token",
  };
  var formBody = [];
  for (var property in postData) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(postData[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");

  const token = client_credentials_token;
  const res = (
    await fetch(`${BASE_URL}/tokens`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formBody,
    })
  ).json();

  const body = await res;
  return body;
};

/**
 * name: logout
 * args: device_token (string)
 * return promise response
 *
 */
export const logout = async (password_credentials_token, device_token) => {
  const postData = {
    device_token: device_token,
  };
  const token = password_credentials_token;
  const res = (
    await fetch(`${BASE_URL}/v4/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(postData),
    })
  ).json();

  const body = await res;
  return body;
};
