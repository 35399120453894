import { Layout, Modal } from "antd";
import SiteHeader from "../components/SiteHeader";
import SiteContent from "../components/SiteContent";
import ReactErrorBoundary from "../components/ReactErrorBoundary";
import SiteFooter from "../components/SiteFooter";
import Popup from "../components/PopUp";
import { usePopup } from "../context/PopupContext";
import { useNavigate } from "react-router";
import { PathConstants } from "../constants/paths";
import { get_time_trail } from "../services/api/General/scripts";
import { useEffect } from "react";

export default function MainLayout() {
  const [modal, contextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const confirmRelogin = () => {
    modal.confirm({
      title: "Confirm",
      content: (
        <div className="flex items-center">
          <p>Session time out, need to Login again</p>
        </div>
      ),
      cancelButtonProps: { style: { display: "none" } },
      onOk: () => {
        sessionStorage.removeItem("password_token");
        setTimeout(() => {
          navigate(PathConstants.Login);
        }, 300);
      },
    });
  };
  const timeOut = async () => {
    const res = await get_time_trail();
  };
  useEffect(() => {
    const intervalId = setInterval(timeOut, 60000);
    const logOutFun = setInterval(confirmRelogin, 3600000);

    // Clear the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
      clearInterval(logOutFun);
    };
  }, []);
  return (
    <Layout className="bg-[#fff]">
      <ReactErrorBoundary>
        <Popup />
        <SiteHeader />
        <SiteContent />
        <SiteFooter />
      </ReactErrorBoundary>
      {contextHolder}
    </Layout>
  );
}
