// import { ErrorPage } from "../constants/pages";
import { ErrorBoundary } from "react-error-boundary";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorPage from "../pages/ErrorPage/ErrorPage";

export default function ReactErrorBoundary(props) {
  useEffect(() => {
    const hasReloaded = localStorage.getItem("hasReloaded");

    if (hasReloaded === null) {
      // This is the first load, so set a flag in localStorage
      localStorage.setItem("hasReloaded", "true");
    }
  }, []);

  const navigate = useNavigate();
  return (
    <ErrorBoundary
      FallbackComponent={<ErrorPage/>}
      onError={(error, errorInfo) => {
        // log the error
        console.log("Error caught!");
        console.error(error);
        console.error(errorInfo);

        // record the error in an APM tool...
      }}
      onReset={() => {
        // reloading the page to restore the initial state
        // of the current page
        console.log("reloading the page...");
        const hasReloaded = localStorage.getItem("hasReloaded");

        if (hasReloaded === "true") {
          localStorage.setItem("hasReloaded", "false");
          window.location.reload();
        } else {
          // Handle what to do after the first reload (e.g., navigate to the homepage)
          // You can use React Router or another navigation method here.
          console.log("Reset the process");
          localStorage.setItem("hasReloaded", "true");
          navigate("/");
        }

        // other reset logic...
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
}